import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../firebase/AuthProvider";
import {
  getParticipantInfo,
  postNewParticipantCertificationDate,
  postNewParticipantCpocDate,
  postNewParticipantCpocEndDate,
  postNewParticipantLinkageDate,
} from "../../../services/participantServices/ParticipantService";
import AssignedParticipant from "../../../interfaces/participant/AssignedParticipant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPencil } from "@fortawesome/free-solid-svg-icons";
import {
  formatDateToMMDDYYYY,
  formatDateToWords,
} from "../../../utilities/dateUtils";
import Banner from "../../ui/Banner/Banner";
import { getTasksForParticipant } from "../../../services/taskServices/TaskService";
import TaskList from "../../../interfaces/task/TaskList";
import TasksPageTable from "../TasksPage/TasksPageTable";
import Avatar from "../../../interfaces/avatar/Avatar";
import { getAvatarsFromStorage } from "../../../services/fileServices/FileService";
import { PagedResult } from "../../../interfaces/PagedResult";

const ParticipantPage: React.FC = () => {
  const { currentUser, currentRoles } = useAuth();

  const { id } = useParams();
  const [participant, setParticipant] = useState<AssignedParticipant>();
  const [changeDetected, setChangeDetected] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [avatars, setAvatars] = useState<Avatar[] | null>([]);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [participantTasks, setParticipantTasks] =
    useState<PagedResult<TaskList>>();
  const [reload, setReload] = useState<boolean>(false);
  const [editLinkageDateInfo, setEditLinkageDateInfo] = useState<string>("");
  const [editCertificationDateInfo, setEditCertificationDateInfo] =
    useState<string>("");
  const [editCpocDateInfo, setEditCpocDateInfo] = useState<string>("");
  const [editCpocEndDateInfo, setEditCpocEndDateInfo] = useState<string>("");
  const [linkageDate, setLinkageDate] = useState<string>("");
  const [certificationDate, setCertificationDate] = useState<string>("");
  const [cpocDate, setCpocDate] = useState<string>("");
  const [cpocEndDate, setCpocEndDate] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(25);
  const [totalTasks, setTotalTasks] = useState<number>(0);

  useEffect(() => {
    if (id && currentUser && !participant) {
      const getParticipant = async () => {
        const authToken = await currentUser.getIdToken();
        const response = await getParticipantInfo(id, authToken);
        if (response !== null) {
          setParticipant(response);
          getParticipantTasks();
        }
      };

      getParticipant();
    }
  }, [id, currentUser, reload]);

  useEffect(() => {
    const storedAvatars = getAvatarsFromStorage();
    setAvatars(storedAvatars);
  }, []);

  const dismissBanner = () => {
    setErrorMessage("");
    setSuccessMessage("");
  };

  const getParticipantTasks = async () => {
    if (currentUser && id) {
      const authToken = await currentUser.getIdToken();
      const tasks = await getTasksForParticipant(authToken, id);
      if (tasks) {
        setParticipantTasks(tasks);
      }
    }
  };

  const clearDisplayInfo = () => {
    setEditCertificationDateInfo("");
    setEditCpocDateInfo("");
    setEditCpocEndDateInfo("");
    setEditLinkageDateInfo("");
    setLinkageDate("");
    setCertificationDate("");
    setCpocDate("");
    setCpocEndDate("");
  };

  const toggleEditLinkageDateInfo = (id: string) => {
    if (editLinkageDateInfo === id) {
      setEditLinkageDateInfo("");
    } else {
      setEditLinkageDateInfo(id);
    }
  };

  const toggleEditCertificationDateInfo = (id: string) => {
    if (editCertificationDateInfo === id) {
      setEditCertificationDateInfo("");
    } else {
      setEditCertificationDateInfo(id);
    }
  };

  const toggleEditCpocDateInfo = (id: string) => {
    if (editCpocDateInfo === id) {
      setEditCpocDateInfo("");
    } else {
      setEditCpocDateInfo(id);
    }
  };

  const toggleEditCpocEndDateInfo = (id: string) => {
    if (editCpocEndDateInfo === id) {
      setEditCpocEndDateInfo("");
    } else {
      setEditCpocEndDateInfo(id);
    }
  };

  const validateLinkageDate = () => {
    const isValid = !isNaN(Date.parse(linkageDate));
    return isValid;
  };

  const validateCertificationDate = () => {
    const isValid = !isNaN(Date.parse(certificationDate));
    return isValid;
  };

  const validateCpocDate = () => {
    const isValid = !isNaN(Date.parse(cpocDate));
    return isValid;
  };

  const validateCpocEndDate = () => {
    const isValid = !isNaN(Date.parse(cpocEndDate));
    return isValid;
  };

  const postNewLinkageDate = async (id: string) => {
    try {
      if (validateLinkageDate() && currentUser) {
        const authToken: string = await currentUser.getIdToken();
        await postNewParticipantLinkageDate(
          id,
          new Date(linkageDate),
          authToken
        );
        setErrorMessage("");
        toggleEditLinkageDateInfo(id);
        clearDisplayInfo();
        setReload(!reload);
      } else if (!validateLinkageDate()) {
        setErrorMessage("Linkage Date is not a valid date format.");
      }
    } catch {
      setErrorMessage("Unable to update linkage date");
    }
  };

  const postNewCertificationDate = async (id: string) => {
    try {
      if (validateCertificationDate() && currentUser) {
        const authToken: string = await currentUser.getIdToken();
        await postNewParticipantCertificationDate(
          id,
          new Date(certificationDate),
          authToken
        );
        setErrorMessage("");
        toggleEditCertificationDateInfo(id);
        clearDisplayInfo();
        setReload(!reload);
      } else if (!validateCertificationDate()) {
        setErrorMessage("Certification Date is not a valid date format.");
      }
    } catch {
      setErrorMessage("Unable to update certification date");
    }
  };

  const postNewCpocDate = async (id: string) => {
    try {
      if (validateCpocDate() && currentUser) {
        const authToken: string = await currentUser.getIdToken();
        await postNewParticipantCpocDate(id, new Date(cpocDate), authToken);
        setErrorMessage("");
        toggleEditCpocDateInfo(id);
        clearDisplayInfo();
        setReload(!reload);
      } else if (!validateCpocDate()) {
        setErrorMessage("CPOC Date is not a valid date format.");
      }
    } catch {
      setErrorMessage("Unable to update CPOC date");
    }
  };

  const postNewCpocEndDate = async (id: string) => {
    try {
      if (validateCpocEndDate() && currentUser) {
        const authToken: string = await currentUser.getIdToken();
        await postNewParticipantCpocEndDate(
          id,
          new Date(cpocEndDate),
          authToken
        );
        setErrorMessage("");
        toggleEditCpocEndDateInfo(id);
        clearDisplayInfo();
        setReload(!reload);
      } else if (!validateCpocEndDate()) {
        setErrorMessage("CPOC Date is not a valid date format.");
      }
    } catch {
      setErrorMessage("Unable to update CPOC date");
    }
  };

  const onChangeLinkageDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = event.target.value;
    setLinkageDate(newDate);
  };

  const onChangeCpocDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = event.target.value;
    setCpocDate(newDate);
  };

  const onChangeCpocEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = event.target.value;
    setCpocEndDate(newDate);
  };

  const onChangeCertificationDate = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newDate = event.target.value;
    setCertificationDate(newDate);
  };

  return (
    <div className="bg-[#F7F9FF] -z-50 ">
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>

      <div className="bg-[#F7F9FF] w-11/12 mx-auto rounded-3xl p-5 relative -top-64 -mt-10 max-w-[1800px] min-h-[500px] border shadow-lg">
        {errorMessage !== "" && (
          <div className="my-5">
            <Banner
              status={"error"}
              message={errorMessage}
              dismissBanner={dismissBanner}
              toggleBanner={dismissBanner}
            />
          </div>
        )}
        {successMessage !== "" && (
          <div className="my-5">
            <Banner
              status={"success"}
              message={successMessage}
              dismissBanner={dismissBanner}
              toggleBanner={dismissBanner}
            />
          </div>
        )}
        <h1 className="text-text inter-font text-2xl">
          Participant Profile Page
        </h1>
        {participant ? (
          <div className="flex flex-row flex-wrap mt-5">
            <div className="flex justify-between flex-col text-sm w-1/2">
              <p className="text-muted">First Name:</p>{" "}
              <p className="bg-gray-200 m-2 dark:bg-gray-200-dark p-2 rounded-lg">
                {participant?.firstName}
              </p>
            </div>
            <div className="flex justify-between flex-col text-sm w-1/2">
              <p className="text-muted">Last Name:</p>{" "}
              <p className="bg-gray-200 m-2 dark:bg-gray-200-dark p-2 rounded-lg">
                {participant?.lastName}
              </p>
            </div>
            <div className="flex justify-between flex-col text-sm w-1/2">
              <p className="text-muted">Waiver Name:</p>{" "}
              <p className="bg-gray-200 m-2 dark:bg-gray-200-dark p-2 rounded-lg">
                {participant?.waiver?.waiverName}
              </p>
            </div>
            <div className="flex justify-between flex-col text-sm w-1/2">
              <p className="text-muted">Participant ID:</p>{" "}
              <p className="bg-gray-200 m-2 dark:bg-gray-200-dark p-2 rounded-lg">
                {participant?.id}
              </p>
            </div>
            <div className="w-1/2">
              <div className="text-muted">CPOC Date: </div>
              <div className="bg-gray-200 m-2 dark:bg-background-dark p-2 rounded-lg">
                {editCpocDateInfo !== participant.id ? (
                  <div className="flex gap-1 items-center justify-between">
                    <div>
                      {participant.cpocDate
                        ? formatDateToWords(participant.cpocDate)
                        : "N/A"}
                    </div>
                    {currentRoles?.some(
                      (role) => role.name === "PROGRAM MANAGER"
                    ) && (
                    <div
                      onClick={() => toggleEditCpocDateInfo(participant.id)}
                      className="text-xs text-muted dark:text-muted-dark"
                    >
                      <FontAwesomeIcon
                        className="cursor-pointer hover:text-muted-dark dark:hover:text-muted"
                        icon={faPencil}
                      />
                    </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <input
                      name="cpocDate"
                      value={cpocDate}
                      onChange={onChangeCpocDate}
                      type="date"
                    />
                    <button
                      onClick={() => postNewCpocDate(participant.id)}
                      className="bg-primary text-white px-2 py-1 m-1 hover:bg-primary-dark text-sm rounded-lg"
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="w-1/2">
              <div className="text-muted">CPOC End Date: </div>
              <div className="bg-gray-200 m-2 dark:bg-background-dark p-2 rounded-lg">
                {editCpocEndDateInfo !== participant.id ? (
                  <div className="flex gap-1 items-center justify-between">
                    <div>
                      {participant.cpocEndDate
                        ? formatDateToWords(participant.cpocEndDate)
                        : "N/A"}
                    </div>
                    {currentRoles?.some(
                      (role) => role.name === "PROGRAM MANAGER"
                    ) && (
                    <div
                      onClick={() => toggleEditCpocEndDateInfo(participant.id)}
                      className="text-xs text-muted dark:text-muted-dark"
                    >
                      <FontAwesomeIcon
                        className="cursor-pointer hover:text-muted-dark dark:hover:text-muted"
                        icon={faPencil}
                      />
                    </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <input
                      name="cpocEndDate"
                      value={cpocEndDate}
                      onChange={onChangeCpocEndDate}
                      type="date"
                    />
                    <button
                      onClick={() => postNewCpocEndDate(participant.id)}
                      className="bg-primary text-white px-2 py-1 m-1 hover:bg-primary-dark text-sm rounded-lg"
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="w-1/2">
              <div className="text-muted">Certification Date: </div>
              <div className="bg-gray-200 m-2 dark:bg-background-dark p-2 rounded-lg">
                {editCertificationDateInfo !== participant.id ? (
                  <div className="flex gap-1 items-center justify-between">
                    <div>
                      {participant.certificationDate
                        ? formatDateToWords(participant.certificationDate)
                        : "N/A"}
                    </div>
                    {currentRoles?.some(
                      (role) => role.name === "PROGRAM MANAGER"
                    ) && (
                      <div
                        onClick={() =>
                          toggleEditCertificationDateInfo(participant.id)
                        }
                        className="text-xs text-muted dark:text-muted-dark"
                      >
                        <FontAwesomeIcon
                          className="cursor-pointer hover:text-muted-dark dark:hover:text-muted"
                          icon={faPencil}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <input
                      name="certificationDate"
                      value={certificationDate}
                      onChange={onChangeCertificationDate}
                      type="date"
                    />
                    <button
                      onClick={() => postNewCertificationDate(participant.id)}
                      className="bg-primary text-white px-2 py-1 m-1 hover:bg-primary-dark text-sm rounded-lg"
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="w-1/2">
              <div className="text-muted">Linkage Date: </div>
              <div className="bg-gray-200 m-2 dark:bg-background-dark p-2 rounded-lg">
                {editLinkageDateInfo !== participant.id ? (
                  <div className="flex gap-1 items-center justify-between">
                    <div>
                      {participant.linkageDate
                        ? formatDateToWords(participant.linkageDate)
                        : "N/A"}
                    </div>
                    {currentRoles?.some(
                      (role) => role.name === "PROGRAM MANAGER"
                    ) && (
                    <div
                      onClick={() => toggleEditLinkageDateInfo(participant.id)}
                      className="text-xs text-muted dark:text-muted-dark"
                    >
                      <FontAwesomeIcon
                        className="cursor-pointer hover:text-muted-dark dark:hover:text-muted"
                        icon={faPencil}
                      />
                    </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <input
                      name="linkageDate"
                      value={linkageDate}
                      onChange={onChangeLinkageDate}
                      type="date"
                    />
                    <button
                      onClick={() => postNewLinkageDate(participant.id)}
                      className="bg-primary text-white px-2 py-1 m-1 hover:bg-primary-dark text-sm rounded-lg"
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="mt-10 w-full">
              <h1 className="text-text inter-font text-2xl mb-5">
                Recent Tasks
              </h1>
              <div className="overflow-x-auto">
                <TasksPageTable
                  tasks={participantTasks?.items || []}
                  avatars={avatars || []}
                  displayTaskCount={false}
                  page={page}
                  pageSize={pageSize}
                  totalCount={totalTasks}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        ) : (
          "Loading..."
        )}
      </div>
    </div>
  );
};

export default ParticipantPage;
