// TeamMemberDashboard.tsx
import React, { useEffect } from "react";
import DashboardStats from "./DashboardStats";
import { useAuth } from "../../../firebase/AuthProvider";
import TeamStats from "./TeamStats";
import { useLocation } from "react-router-dom";
import Tasks from "../tasks/Tasks";

const TeamMemberDashboard: React.FC = () => {
  const { currentUser, currentRoles } = useAuth();
  const location = useLocation();
  const userId = location.pathname.split("/")[2];

  useEffect(() => {
    if (currentRoles) {
    }
  }, [currentRoles]);

  return (
    <div className="bg-background dark:bg-background-dark sm:min-w-[640px] overflow-x-auto w-full h-screen">
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>
      <div className="relative flex bg-[#F7F9FC] dark:bg-[#141416] w-[1200px] mx-auto">
        {/* DashboardStats on the left */}
        {/* Content on the right */}

        <div className="relative -top-40 flex-1 flex-col w-[1200px]">
          <Tasks userId={userId} />
          {currentRoles?.some((role) => role.name === "SUPERVISOR") && (
            <div className="flex justify-center items-center flex-wrap w-[1200px]">
              <div className="flex mt-16">
                <DashboardStats userId={userId} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamMemberDashboard;
