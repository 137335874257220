// dateUtils.ts
export function formatDateToMMDDYYYY(date: Date): string {
  date = new Date(date);
  const formattedDate = date.toLocaleDateString("en-us", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  return formattedDate.replace(/\//g, "/");
}

export function formatDateToWords(date: Date): string {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  date = new Date(date);
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}

export function getTimeAgo(completionTime: Date): string {
  if (!completionTime) return "";

  const completionDate = new Date(completionTime);
  const now = new Date();

  const seconds = Math.abs(
    Math.floor((now.getTime() - completionDate.getTime()) / 1000)
  );

  // Check if the event occurred within 30 seconds
  if (seconds < 30) {
    return "Now";
  }

  // Check if the event occurred within the last hour
  if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
  }

  // Check if the event occurred within the last 24 hours
  if (seconds < 86400) {
    const hours = Math.floor(seconds / 3600);
    return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
  }

  // If the event occurred more than 24 hours ago, format the date
  const daysDifference = Math.floor(seconds / 86400);
  
  if (daysDifference === 1) {
    // Return "Yesterday" with time
    const options: Intl.DateTimeFormatOptions = { hour: "numeric", minute: "numeric", hour12: true };
    return `Yesterday ${completionDate.toLocaleTimeString([], options)}`;
  }

  // For events older than 24 hours, format as "Wed. January 27th" or with the year if not the current year
  const currentYear = now.getFullYear();
  const eventYear = completionDate.getFullYear();
  
  const formattedDate = completionDate.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: eventYear !== currentYear ? "numeric" : undefined, // Include year only if it's not the current year
  });

  return formattedDate;
}

export function calculateDateProximity(date: Date): string {
  const newDate = new Date(date);
  const today = new Date();
  const endOfToday = new Date(today);
  endOfToday.setHours(23, 59, 59, 999);

  const endOfThisWeek = new Date(today);
  endOfThisWeek.setDate(endOfThisWeek.getDate() + (7 - endOfThisWeek.getDay()));

  const endOfThisMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  endOfThisMonth.setHours(23, 59, 59, 999);

  if (newDate >= today && newDate <= endOfToday) {
    return "Due Today";
  } else if (newDate > endOfToday && newDate <= endOfThisWeek) {
    return "Due Soon";
  } else if (newDate > endOfThisWeek && newDate <= endOfThisMonth) {
    return "Due This Month";
  } else if (newDate < today) {
    return "Overdue";
  } else {
    return "On Time";
  }
}

export const convertMinutesToHours = (minutes: number): string => {
  if (minutes === 0) {
    return "0";
  }

  const hours = minutes / 60;
  // Round to 2 decimal places
  const roundedHours = Math.round(hours * 100) / 100;
  // Convert to string with 2 decimal places, unless it's 0
  return roundedHours.toFixed(2).replace(/\.00$/, "");
};


export function getDateXDaysInFuture(days: number | undefined): string {
  const currentDate = new Date(); // Get current date
  if (days) {
    const futureDate = new Date(
      currentDate.getTime() + days * 24 * 60 * 60 * 1000
    ); // Calculate future date in milliseconds
    // Extract year, month, and day from futureDate
    const year = futureDate.getFullYear();
    const month = `${futureDate.getMonth() + 1}`.padStart(2, "0"); // Month is zero-based, so we add 1
    const day = `${futureDate.getDate()}`.padStart(2, "0");

    // Return formatted date string in 'yyyy-MM-dd' format
    return `${year}-${month}-${day}`;
  }
  return "";
}

export function formatDateToDateFormFormat(date:Date)  {
  const d = new Date(date);
  const month = `${d.getMonth() + 1}`.padStart(2, '0');
  const day = `${d.getDate()}`.padStart(2, '0');
  const year = d.getFullYear();
  return `${year}-${month}-${day}`;
};

export function formatDateForInput(date: Date) {
  // Adjust for timezone offset
  const tzOffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
  const localDate = new Date(date.getTime() - tzOffset);

  // Manually format the date components
  const year = localDate.getFullYear();
  const month = String(localDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
  const day = String(localDate.getDate()).padStart(2, '0');
  const hours = String(localDate.getHours()).padStart(2, '0');
  const minutes = String(localDate.getMinutes()).padStart(2, '0');

  // Return the formatted string
  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

