// file: Drive.tsx
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../../firebase/AuthProvider";
import StorageCircleGraph from "./driveStats/StorageCircleGraph";
import { formatFileSize } from "../../../../utilities/fileUtils";
import { getTotalUserDriveSize } from "../../../../services/fileServices/UserDriverService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";

interface DriveProps {
  employeeId?: string;
}

const Drive: React.FC<DriveProps> = ({ employeeId }) => {
  const { currentUser, currentRoles } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [isCaseFileDrive, setIsCaseFileDrive] = useState<boolean>(
    location.pathname.includes("caseFileDrive")
  );
  const hasHigherRoleThanSC = currentRoles?.some((role) => role.name !== "SC");
  const [driveSize, setDriveSize] = useState<number>(0);

  const [contextMenu, setContextMenu] = useState<{
    x: number;
    y: number;
  } | null>(null);

  // Handle right-click to show the custom menu
  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu({
      x: event.clientX,
      y: event.clientY,
    });
  };

  // Hide the menu when clicking elsewhere
  const handleClick = () => {
    setContextMenu(null);
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    // You can do checks on currentUser, if needed
    if (!currentUser) return;
    const getDriveSize = async () => {
      const authToken = await currentUser.getIdToken();
      let size;
      if (employeeId && hasHigherRoleThanSC) { 
        size = await getTotalUserDriveSize(authToken, employeeId);
      } else { 
        size = await getTotalUserDriveSize(authToken);
      }
      if (size) {
        setDriveSize(size);
      }
    };
    getDriveSize();
  }, [currentUser]);

  // We'll handle toggling between "CaseFileDrive" and "PersonalDrive"
  // by calling `navigate(...)` to the correct route under /drive
  const toggleDrive = (driveType: string) => {
    if (driveType === "case") {
      setIsCaseFileDrive(true);
      // navigate to /drive/caseFileDrive
      navigate("caseFileDrive");
    } else {
      setIsCaseFileDrive(false);
      // Later: /drive/personalDrive
      navigate("personal");
    }
  };

  return (
    <div
      className="relative bg-[#F7F9FC] dark:bg-background-dark sm:min-w-[640px] 
                  overflow-x-auto max-w-full overflow-hidden h-[88vh]"
      onContextMenu={handleContextMenu}
    >
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>
      <div
        className="-top-64 relative dark:bg-card-dark dark:text-text-dark 
                        bg-[#F7F9FF] rounded-3xl shadow-lg w-11/12 mx-auto 
                        min-h-[600px] -mt-10 overflow-auto -mb-64"
      >
        <div className="flex h-max">
          {/* SIDE NAV */}
          <div className="min-h-[600px] bg-gray-200 min-w-[220px] flex flex-col py-4 px-2 rounded-lg w-2/12 justify-between">
            <div className="flex flex-col gap-5">
              <div className="title inter-font font-bold text-xl mx-2">
                User Drive
              </div>

              {/* Toggle between the two drives */}
              <div className="toggle text-[12px] flex justify-around inter-font items-center align-middle border bg-[#F7F9FF] w-[200px] max-auto p-1 rounded-3xl text-center">
                <div
                  className={`toggle-item p-2 rounded-3xl w-full cursor-pointer ${
                    isCaseFileDrive
                      ? "bg-gray-500 text-white"
                      : "hover:bg-gray-300"
                  }`}
                  onClick={() => toggleDrive("case")}
                >
                  Case Files
                </div>

                <div
                  className={`toggle-item p-2 rounded-3xl w-full cursor-pointer ${
                    !isCaseFileDrive
                      ? "bg-gray-500 text-white"
                      : "hover:bg-gray-300"
                  }`}
                  onClick={() => toggleDrive("personal")}
                >
                  Personal
                </div>
              </div>
              {!isCaseFileDrive && (
                <div className="px-4 py-2 hover:bg-gray-500 hover:text-white rounded-xl cursor-pointer flex gap-3 text-base align-middle items-center">
                  <FontAwesomeIcon icon={faShareNodes} /> Shared
                </div>
              )}
            </div>
            <div className="relative flex flex-wrap justify-center items-center align-middle">
              <h2 className="text-text inter-font text-base">Storage Used: </h2>
              {/* 15 GB total in Bytes */}
              <div className="text-muted mb-5 text-sm">
                {formatFileSize(driveSize)} / {formatFileSize(16105889280)}
              </div>
              <StorageCircleGraph
                freeStorage={16105889280}
                usedStorage={driveSize}
              />
            </div>
          </div>

          {/* CONTENT AREA for Child Routes */}
          <div className="content p-4 w-full mb-4 overflow-y-auto max-h-full">
            {/*
                This is where child routes get rendered.
                If the user navigates to /drive/caseFileDrive,
                the <CaseFileDrive> component will appear here.
              */}
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drive;
