import React, { useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { useAuth } from "../../../../firebase/AuthProvider";
import TemplateFile from "../../../../interfaces/task/TaskFiles/TemplateFile";
import { getTemplateFiles } from "../../../../services/taskServices/TaskLKService";
import { publishTemplateRequiredFile } from "../../../../services/templateService/TemplateTaskService";

interface AddRequiredFileProps {
  taskId: number | undefined;
  reload: () => void;
}

interface OptionType {
  value: string;
  label: string;
}

const AddRequiredFile: React.FC<AddRequiredFileProps> = ({
  taskId,
  reload,
}) => {
  const [taskIdNum, setTaskIdNum] = useState<string>();
  const { currentUser } = useAuth();

  const [fileTemplates, setFileTemplates] = useState<TemplateFile[] | null>([]);
  const [options, setOptions] = useState<OptionType[]>([]);
  const [selectedFile, setSelectedFile] = useState<OptionType | null>(null);
  const [requirements, setRequirements] = useState<TemplateFile[]>([]);

  useEffect(() => {
    setTaskIdNum(taskIdNum);

    if (currentUser !== null && 3 !== undefined) {
      const fetchTemplateFiles = async () => {
        const authToken: string = await currentUser.getIdToken();
        const fetchedTemplateFiles = await getTemplateFiles(authToken);
        setFileTemplates(fetchedTemplateFiles);
      };

      fetchTemplateFiles();
    }
  }, [currentUser, taskId, reload]);

  useEffect(() => {
    if (fileTemplates) {
      const formattedTemplateOptions = fileTemplates.map((file) => ({
        value: file.id.toString(),
        label: file.fileName,
      }));

      setOptions(formattedTemplateOptions);
    }
  }, [fileTemplates]);

  const handleAddFile = (selectedOption: SingleValue<OptionType>) => {
    const file = selectedOption;
    setSelectedFile(file);
  };

  const submitTemplateTaskFiles = async () => {
    if (taskId !== undefined) {
      if (currentUser !== null && fileTemplates && selectedFile) {
        try {
          const foundTemplate = fileTemplates.find(
            (template) => template.id.toString() === selectedFile.value
          );
          const authToken: string = await currentUser.getIdToken();
          // Create an array of promises
          if (foundTemplate) {
            await publishTemplateRequiredFile(foundTemplate, taskId, authToken);
          }

          // Wait for all promises to resolve

          // Only executed if all promises resolve successfully
          reload();
        } catch (error) {
          console.error("An error occurred:", error);
          // Handle any errors here (e.g., show an error message to the user)
        }
      }
    }
  };

  return (
    <div className="">
      <div className="mx-auto block bg-card dark:bg-card-dark mt-10 rounded-lg shadow-xl p-10 border">
        <div className="font-bold text-primary">
          Last step, let's finish strong!{" "}
        </div>
        <div className="mt-2 text-muted">
          All you need to do here is simply select the files from a list of
          pre-defined templates that are required to be added to the task.
        </div>
        <div className="my-4 mx-auto">
          <Select
            id="file"
            name="file"
            value={selectedFile}
            placeholder="Select..."
            onChange={handleAddFile}
            options={options}
            className="w-3/4"
            classNamePrefix="select"
          />
        </div>
        <div className="mt-5">
          <div className="flex gap-2 justify-end">
          <button className="bg-brandSecondary text-white rounded-lg ms-auto p-2 min-w-[75px]" onClick={submitTemplateTaskFiles}>
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRequiredFile;
