// NotificationProvider.tsx
import React, { createContext, useContext, ReactNode } from "react";
import { ToastContainer, toast, ToastOptions } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

interface NotificationContextProps {
  notify: (message: ReactNode, options?: ToastOptions) => void;
}

const NotificationContext = createContext<NotificationContextProps>({
  notify: () => {},
});

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // The function that fires the toast
  const notify = (message: ReactNode, options?: ToastOptions) => {
    toast(message, options);
  };

  return (
    <NotificationContext.Provider value={{ notify }}>
      {children}
      <ToastContainer
        stacked
        position="bottom-right"
        autoClose={5000}
        newestOnTop={true}
        closeOnClick
        pauseOnHover
        draggable
      />
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationContext);
