// FileLKValuesService.ts

import { FileType } from "../../interfaces/task/TaskFiles/FileType";

// Function to fetch all participants of an employee
export async function getFileLKValues(
  authToken: string
): Promise<FileType[] | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/file/types`;

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (response.ok) {
      const types = await response.json();
      return types
    } else { 
        console.log(response.status);
        return null;
    }
  } catch (error) {
    console.error("Error uploading file:", error);
    // Handle error
    return null;
  }
}


