import { useEffect, useState } from "react";
import { User } from "../../../interfaces/User";
import { getAvatarsFromStorage } from "../../../services/fileServices/FileService";
import Avatar from "../../../interfaces/avatar/Avatar";
import UserFolderCard from "../userComponents/UserFolderCard";

const AssigneeWithTooltip: React.FC<{ assignee: User }> = ({ assignee }) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [currentAvatar, setCurrentAvatar] = useState<Avatar | null>();

  useEffect(() => {
    if (assignee) {
      const storedAvatars = getAvatarsFromStorage();
      if (storedAvatars) {
        const avatar = storedAvatars.find(
          (avatar) => avatar.id === assignee.id
        );
        if (avatar) {
          setCurrentAvatar(avatar);
        }
      }
    }
  }, [assignee]);

  return (
    <div>
      {assignee && (
        <div
          className="relative z-50"
          onMouseEnter={() => setTooltipVisible(true)}
          onMouseLeave={() => setTooltipVisible(false)}
        >
          {currentAvatar ? (
            <img
              src={`data:image/png;base64,${currentAvatar.avatar}`}
              alt="profile_picture"
              className="h-8 w-8 rounded-full me-2"
            />
          ) : (
            <div className="w-8 h-8 font-bold flex items-center justify-center rounded-full bg-gray-300 dark:text-text">
              <div className="text-sm">
                {assignee.firstName[0] + "" + assignee.lastName[0]}
              </div>
            </div>
          )}
          <div className="absolute">
            {isTooltipVisible && (
              <div className="mt-1">
                <UserFolderCard user={assignee} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AssigneeWithTooltip;
