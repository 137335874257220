import React, { useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { useAuth } from "../../../firebase/AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";
import ActionSubType from "../../../interfaces/task/taskActions/ActionSubType";
import {
  getTaskActionSubTypes,
  publishTaskActionTemplate,
} from "../../../services/taskServices/TaskActionService";
import NewTaskActionType from "../../../interfaces/task/taskActions/NewTaskActionType";
import TemplateFile from "../../../interfaces/task/TaskFiles/TemplateFile";
import { getTemplateFiles } from "../../../services/fileServices/FileService";

interface OptionType {
  value: string;
  label: string;
}

const CreateNewTaskActivityType: React.FC = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [actionTypes, setActionTypes] = useState<ActionSubType[] | null>(null);
  const [actionTypeOptions, setActionTypeOptions] = useState<OptionType[]>([]);
  const [taskId, setTaskId] = useState<string>("");
  const [fileTemplates, setFileTemplates] = useState<TemplateFile[] | null>();
  const [formattedFileTemplates, setFormattedFileTemplates] = useState<
    OptionType[]
  >([]);

  const labelsToFilterOut = [
    "COMMENT",
    "TASK FILE DELETED",
    "FILE UPLOAD",
    "TASK APPROVAL",
    "TASK SUBMISSION",
    "TASK COMPLETED",
    "REJECTED",
  ];

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const taskId = searchParams.get("taskId");
    if (taskId !== null) setTaskId(taskId);
  }, [location]);

  useEffect(() => {
    if (!actionTypes && currentUser) {
      const getActionTypes = async () => {
        const authToken = await currentUser.getIdToken();
        const retrievedTypes = await getTaskActionSubTypes(authToken);
        if (retrievedTypes) {
          const filteredOptions = retrievedTypes.filter(
            (option) => !labelsToFilterOut.includes(option.actionSubTypeName)
          );
          setActionTypes(filteredOptions);
        }
      };
      getActionTypes();
    }
    if (currentUser !== null) {
      const fetchTemplateFiles = async () => {
        const authToken: string = await currentUser.getIdToken();
        const fetchedTemplateFiles = await getTemplateFiles(authToken);
        setFileTemplates(fetchedTemplateFiles);
      };

      fetchTemplateFiles();
    }
  }, [currentUser]);

  useEffect(() => {
    if (taskId) {
      setNewAction((prev) => ({
        ...prev,
        taskId: taskId.split("-")[1], // Update taskId in newAction when taskId changes
      }));
    }
  }, [taskId]);

  useEffect(() => {
    if (fileTemplates) {
      const formattedFileTemplates = fileTemplates.map((file) => ({
        value: file.id.toString(),
        label: file.fileName,
      }));
      if (formattedFileTemplates) {
        const updatedFileTemplates = formattedFileTemplates.concat({
          value: "0",
          label: "None",
        });
        setFormattedFileTemplates(updatedFileTemplates);
      }
    }
  }, [fileTemplates]);

  const [newAction, setNewAction] = useState<NewTaskActionType>({
    activityName: "",
    description: "",
    actionTypeId: "",
    actionSubTypeId: "",
    estimated_ttc: "0",
    requiresFile: "",
  });

  useEffect(() => {
    if (actionTypes && actionTypes.length > 0) {
      const formattedActionTypes = actionTypes.map((at) => ({
        value: at.id.toString(),
        label: at.actionSubTypeName,
      }));
      setActionTypeOptions(formattedActionTypes);
    }
  }, [actionTypes]); // React only when actionTypes changes

  const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    setNewAction({ ...newAction, estimated_ttc: value.toString() });
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setNewAction({ ...newAction, [name]: value });
  };

  const validateFields = (): boolean => {
    // Check if any required fields are empty
    if (
      !newAction.description ||
      !newAction.estimated_ttc ||
      !newAction.activityName
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Validate fields
    if (!validateFields()) {
      // Handle invalid fields (e.g., show error message)
      console.error("Please fill out all required fields.");
      return;
    }
    if (currentUser) {
      const authToken: string = await currentUser.getIdToken();
      // console.log('POSTING NEW ACTION', newAction)
      await publishTaskActionTemplate(authToken, newAction)
        .then((data) => {
          // console.log('Success:', data);
          // Navigate to success page or handle success state
          navigate(`/task/edit/activity/template`, { replace: true });
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle error state (e.g., show error message)
        });
    }
  };

  const handleActionRequiresFileChange = (
    selectedOption: SingleValue<OptionType>
  ) => {
    const requiresFile = selectedOption ? selectedOption.value : "";
    setNewAction({ ...newAction, requiresFile });
  };

  return (
    <div className="bg-[#F7F9FC]">
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>
      <div className="min-h-[600px] mx-auto text-text dark:text-text-dark p-5 relative w-11/12 rounded-3xl -top-64 bg-white">
        <form
          onSubmit={handleSubmit}
          className="max-w-xl mx-auto mt-8 dark:text-text dark:bg-card-dark shadow-md rounded-3xl px-8 pt-6 pb-8 mb-4 bg-background"
        >
          <h1 className="heading-2 text-text inter-font text-center">New Activity Type</h1>
          <div className="mb-4">
            <label
              htmlFor="description"
              className="block text-text dark:text-text-dark text-sm font-bold mb-2"
            >
              Activity Name:
            </label>
            <textarea
              id="activityName"
              name="activityName"
              placeholder="A name for the activity..."
              value={newAction.activityName}
              onChange={handleChange}
              required
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-text dark:text-text leading-tight focus:outline-none focus:shadow-outline `}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="description"
              className="block text-text dark:text-text-dark text-sm font-bold mb-2"
            >
              Description:
            </label>
            <textarea
              id="description"
              name="description"
              placeholder="A brief description of what the task entails..."
              value={newAction.description}
              onChange={handleChange}
              required
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-text dark:text-text leading-tight focus:outline-none focus:shadow-outline`}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="durationInMinutes"
              className="block text-text dark:text-text-dark text-sm font-bold mb-2"
            >
              Duration (in minutes):
            </label>
            <input
              type="number"
              id="durationInMinutes"
              name="durationInMinutes"
              value={newAction.estimated_ttc}
              onChange={handleDurationChange}
              min="0"
              step="15"
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-text leading-tight focus:outline-none focus:shadow-outline`}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="durationInMinutes"
              className="block text-text dark:text-text-dark text-sm font-bold mb-2"
            >
              Required File{" "}
              <span className="text-muted dark:text-muted-dark">
                (Optional)
              </span>
              :
            </label>
            <Select
              options={formattedFileTemplates}
              onChange={handleActionRequiresFileChange}
              value={formattedFileTemplates.find(
                (option) => option.value === newAction.requiresFile
              )}
            />{" "}
          </div>

          <div className="flex justify-end">
            <button
              onClick={(e) => handleSubmit}
              type="submit"
              className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Create Task
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateNewTaskActivityType;
