// ParticipantService.ts

import Participant from "../../interfaces/Participant";
import AssignedParticipant from "../../interfaces/participant/AssignedParticipant";
import NewParticipant from "../../interfaces/participant/NewParticipant";
import Waiver from "../../interfaces/participant/Waiver";

// Function to fetch all participants of an employee
export async function getAssignedParticipants(
  authToken: string
): Promise<Participant[] | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/participants`;

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    const res = await response.json();

    // Map the participant data
    const participants: Participant[] = res.map((part: any) => ({
        id: part.id, 
        firstName: part.firstName, 
        lastName: part.lastName, 
        region: part.region,
        org: part.organizationId
    }));


    return participants;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching participants:", error);
    return null;
  }
}


// Function to fetch all participants of an employee & managed employess
export async function getAllParticipants(
  authToken: string
): Promise<AssignedParticipant[] | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/participants/all`;

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    const res = await response.json();

    // Map the participant data
    const participants: AssignedParticipant[] = res.map((part: any) => ({
        id: part.id, 
        firstName: part.firstName, 
        lastName: part.lastName, 
        deactivated: part.deactivated,
        linkageDate: part.linkageDate,
        certificationDate: part.certificationDate, 
        cpocDate: part.cpocDate,
        cpocEndDate: part.cpocEndDate,
        region: part.regionId,
        waiver: part.waiver,
        assignee: part.assignee,
        org: part.organizationId
    }));

    return participants;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching participants:", error);
    return null;
  }
}

// Function to fetch all participants of an employee & managed employess
export async function getParticipantInfo(
  participantId: string,
  authToken: string
): Promise<AssignedParticipant | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/participants/${participantId}`;

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    const part = await response.json();

    // Map the participant data
    const participant: AssignedParticipant = {
        id: part.id, 
        firstName: part.firstName, 
        lastName: part.lastName, 
        deactivated: part.deactivated,
        linkageDate: part.linkageDate,
        certificationDate: part.certificationDate, 
        cpocDate: part.cpocDate,
        cpocEndDate: part.cpocEndDate,
        region: part.regionId,
        waiver: part.waiver,
        assignee: part.assignee,
        org: part.organizationId
    };

    return participant;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching participants:", error);
    return null;
  }
}

export async function toggleParticipantDeactivated(
  participantId: string,
  authToken: string
): Promise<boolean | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/participants/toggleActivation/${participantId}`;

    const response = await fetch(apiUrl, {
      method: 'POST', 
      headers: {
        Authorization: `Bearer ${authToken}`,
      },

    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return false;
    }

    const res = await response.text();
    return true;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error toggling participant:", error);
    return false;
  }
}


// Function to fetch all participants of an employee & managed employess
export async function getWaivers(
  authToken: string
): Promise<Waiver[] | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/participants/waivers`;

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    const res = await response.json();

    // Map the participant data
    const waivers: Waiver[] = res.map((waiver: any) => ({
        id: waiver.id, 
        waiverName: waiver.waiverName
    }));

    return waivers;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching waivers:", error);
    return null;
  }
}

export async function postParticipant(
  newParticipant: NewParticipant,
  authToken: string
): Promise<number | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/participants`;

    const requestBody = { 
      firstName: newParticipant.firstName,
      lastName: newParticipant.lastName,
      waiverId: newParticipant.waiverId,
      regionId: 2, // Need to replace this 
      caseworker: newParticipant.caseworker,
      linkageDate: newParticipant.linkageDate, 
      certificationDate: newParticipant.certificationDate,  
      cpocDate: newParticipant.cpocDate,
      cpocEndDate: newParticipant.cpocEndDate,
      initial: newParticipant.initial
    }

    const response = await fetch(apiUrl, {
      method: 'POST', 
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    const res: number = await response.json();
    return res;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error creating participant:", error);
    return null;
  }
}

export async function postParticipantBump(
  participantId: string,
  waiverid: string,
  authToken: string
): Promise<number | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/participants/bump`;

    const requestBody = { 
      participantId: participantId, 
      waiverId: waiverid
    }

    const response = await fetch(apiUrl, {
      method: 'PUT', 
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    const res: number = await response.json();
    return res;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error creating participant:", error);
    return null;
  }
}

export async function postNewParticipantLinkageDate(
  id: string,
  linkageDate: Date,
  authToken: string
): Promise<number | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/participants/linkageDate`;

    const requestBody = { 
      participantId: id, 
      linkageDate: linkageDate
    }

    const response = await fetch(apiUrl, {
      method: 'POST', 
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    const res: number = await response.json();
    return res;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error posting new participant linkageDate:", error);
    return null;
  }
}

export async function postNewParticipantCpocDate (
  id: string,
  date: Date,
  authToken: string
): Promise<number | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/participants/cpocDate`;

    const requestBody = { 
      participantId: id, 
      linkageDate: date
    }

    const response = await fetch(apiUrl, {
      method: 'POST', 
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    const res: number = await response.json();
    return res;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error posting new participant linkageDate:", error);
    return null;
  }
}

export async function postNewParticipantCpocEndDate (
  id: string,
  date: Date,
  authToken: string
): Promise<number | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/participants/cpocEndDate`;

    const requestBody = { 
      participantId: id, 
      linkageDate: date
    }

    const response = await fetch(apiUrl, {
      method: 'POST', 
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    const res: number = await response.json();
    return res;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error posting new participant linkageDate:", error);
    return null;
  }
}

export async function postNewParticipantCertificationDate (
  id: string,
  date: Date,
  authToken: string
): Promise<number | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/participants/certificationDate`;

    const requestBody = { 
      participantId: id, 
      linkageDate: date
    }

    const response = await fetch(apiUrl, {
      method: 'POST', 
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    const res: number = await response.json();
    return res;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error posting new participant linkageDate:", error);
    return null;
  }
}

export async function reassigneParticipant(
  participantId: string, 
  employeeId: string, 
  authToken: string
): Promise<boolean | null> { 
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/participants/transfer`;

    const requestBody = { 
      participantId: participantId, 
      newAssigneeId: employeeId
    }

    const response = await fetch(apiUrl, {
      method: 'POST', 
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return false;
    }

    const res: number = await response.json();
    return true;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error posting new participant linkageDate:", error);
    return false;
  }
}