import React, { useEffect, useState } from "react";
import AdministrativeTransactionRequest from "../../../interfaces/adminRequests/AdministrativeTransactionRequest";
import {
  approveAdministrativeTransactionRequest,
  getAdministrativeTransactionRequestsForUser,
  rejectAdministrativeTransactionRequest,
} from "../../../services/AdministrativeRequestServices/AdministrativeRequestService";
import { useAuth } from "../../../firebase/AuthProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const AdminDashboard: React.FC = () => {
  const { currentUser, currentOrganization } = useAuth();
  const navigate = useNavigate();

  const [transactions, setTransactions] = useState<
    AdministrativeTransactionRequest[] | null
  >(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchTransactions = async () => {
    try {
      if (currentUser) {
        const authToken = await currentUser.getIdToken();
        const fetchedTransactions =
          await getAdministrativeTransactionRequestsForUser(
            authToken,
            currentUser.uid
          );

        if (fetchedTransactions) {
          setTransactions(fetchedTransactions);
        } else {
          setError("Failed to fetch transactions.");
        }
      }
    } catch (err) {
      setError("An error occurred while fetching transactions.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  if (loading) {
    return <div>Loading transactions...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!transactions || transactions.length === 0) {
    return <div>No transactions available.</div>;
  }

  const triggerTaskApproval = async (requestId: number) => {
    if (currentUser) {
      const authToken = await currentUser.getIdToken();
      const result = await approveAdministrativeTransactionRequest(
        authToken,
        requestId
      );
      if (result) {
        // Refresh the list after successful approval
        await fetchTransactions();
      } else {
        console.error("Failed to approve request.");
      }
    }
  };

  const triggerTaskRejection = async (requestId: number) => {
    if (currentUser) {
      const authToken = await currentUser.getIdToken();
      const result = await rejectAdministrativeTransactionRequest(
        authToken,
        requestId
      );
      if (result) {
        // Refresh the list after successful rejection
        await fetchTransactions();
      } else {
        console.error("Failed to reject request.");
      }
    }
  };

  return (
    <div className="relative flex flex-wrap flex-grow w-full justify-center bg-[#F7F9FC] dark:bg-[#141416] h-auto">
      <div className="admin-dashboard bg-[#F7F9FC] dark:bg-[#141416] h-auto shadow-lg border rounded-lg p-5 relative min-h-[400px] -top-40">
        <h1 className="inter-font text-text text-2xl mb-5">
          Administrative Transaction Requests
        </h1>
        <table className="transactions-table w-full border-collapse border border-gray-200 dark:border-gray-700">
          <thead>
            <tr className="bg-gray-100 dark:bg-gray-800 text-left">
              <th className="border border-gray-300 dark:border-gray-600 p-2 w-10 truncate">
                ID
              </th>
              <th className="border border-gray-300 dark:border-gray-600 p-2 w-40 truncate">
                Requested By
              </th>
              <th className="border border-gray-300 dark:border-gray-600 p-2 w-40 truncate">
                Approver
              </th>
              <th className="border border-gray-300 dark:border-gray-600 p-2 w-40 truncate">
                Type
              </th>
              <th className="border border-gray-300 dark:border-gray-600 p-2 w-20 truncate">
                Task
              </th>
              <th className="border border-gray-300 dark:border-gray-600 p-2 w-56 truncate">
                Request Time
              </th>
              <th className="border border-gray-300 dark:border-gray-600 p-2 w-40 truncate">
                Status
              </th>
              <th className="border border-gray-300 dark:border-gray-600 p-2 w-40 truncate">
                Completion Time
              </th>
              <th className="border border-gray-300 dark:border-gray-600 p-2 w-10 truncate">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction) => (
              <tr
                key={transaction.id}
                className="even:bg-gray-50 dark:even:bg-gray-700"
              >
                <td className="border border-gray-300 dark:border-gray-600 p-2 truncate">
                  {transaction.id}
                </td>
                <td className="border border-gray-300 dark:border-gray-600 p-2 truncate">
                  {transaction.requesterName}
                </td>
                <td className="border border-gray-300 dark:border-gray-600 p-2 truncate">
                  {transaction.approverName || "N/A"}
                </td>
                <td className="border border-gray-300 dark:border-gray-600 p-2 truncate">
                  {transaction.transactionType}
                </td>
                <td className="border border-gray-300 dark:border-gray-600 p-2 truncate text-center">
                  {transaction.taskId && (
                    <div
                      className="text-blue-500 cursor-pointer"
                      onClick={() =>
                        navigate(
                          `/task/${currentOrganization?.name.toUpperCase()}-${
                            transaction.taskId
                          }`
                        )
                      }
                    >
                      {currentOrganization?.name.toUpperCase()}-
                      {transaction.taskId}
                    </div>
                  )}
                  {transaction.templateTaskId && (
                    <div className="">
                      TEMPLATE-
                      {transaction.templateTaskId}
                    </div>
                  )}
                  {!transaction.taskId && !transaction.templateTaskId && (
                    <div>--</div>
                  )}
                </td>
                <td className="border border-gray-300 dark:border-gray-600 p-2 truncate">
                  {new Date(transaction.requestTime).toLocaleString()}
                </td>
                <td
                  className={`border border-gray-300 dark:border-gray-600 p-2 truncate 
                  ${transaction.status === "approved" ? "text-green-500" : ""}
                  ${transaction.status === "rejected" ? "text-red-500" : ""}`}
                >
                  {transaction.status}
                </td>
                <td className="border border-gray-300 dark:border-gray-600 p-2 truncate">
                  {transaction.completionTime
                    ? new Date(transaction.completionTime).toLocaleString()
                    : "Pending..."}
                </td>
                <td className="border border-gray-300 dark:border-gray-600 p-2 truncate">
                  {transaction.status !== "approved" &&
                  transaction.status !== "rejected" ? (
                    <div className="flex justify-between">
                      <button
                        className="bg-[#3dd598] text-white rounded-lg w-6 h-6"
                        onClick={() => triggerTaskApproval(transaction.id)}
                      >
                        <FontAwesomeIcon icon={faCheck} />
                      </button>
                      <button
                        className="bg-red-500 text-white rounded-lg w-6 h-6"
                        onClick={() => triggerTaskRejection(transaction.id)}
                      >
                        <FontAwesomeIcon icon={faX} />
                      </button>
                    </div>
                  ) : (
                    <div className="flex justify-center text-center align-middle items-center">
                      --
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminDashboard;
