// src/components/userDrive/PersonalDrive.tsx
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DriveList from "../driveItems/DriveList";
import DriveSearch from "../driveItems/DriveSearch";
import { DriveItem } from "../../../../../interfaces/caseFileDrive/DriveFiles";
import { useDriveContext } from "../../../../../contexts/DriveContext";
import { useAuth } from "../../../../../firebase/AuthProvider";
import {
  getUserFolderDataAsDriveItems,
} from "../../../../../services/fileServices/FileService";
import CustomContextMenu from "../userDrive/RightClickComponent";
import { UploadFile } from "../userDrive/UploadFile";
import { CreateFolder } from "../userDrive/CreateFolder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical, faList } from "@fortawesome/free-solid-svg-icons";
import {
  downloadUserFile,
  removeFile,
  removeFolder,
  renameFile,
  renameFolder,
} from "../../../../../services/fileServices/UserDriverService";
import Banner from "../../../../ui/Banner/Banner";

function PersonalDrive() {
  const { currentUser } = useAuth();
  const { folderId } = useParams();
  const navigate = useNavigate();
  const { viewMode, setViewMode } = useDriveContext();

  // The folder + items in the current view
  const [currentFolder, setCurrentFolder] = useState<{
    id: number;
    name: string;
    parentFolderId: number | null;
  } | null>(null);
  const [items, setItems] = useState<DriveItem[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);

  // Right-click state
  const [contextMenu, setContextMenu] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [clickedItem, setClickedItem] = useState<DriveItem | null>(null);

  const [errorMessage, setErrorMessage] = useState<string>("");

  // Toggles for "create folder" and "upload file"
  const [createFolderToggle, setCreateFolderToggle] = useState(false);
  const [uploadFileToggle, setUploadFileToggle] = useState(false);

  // --- Rename state
  const [renameTarget, setRenameTarget] = useState<DriveItem | null>(null);
  const [renameValue, setRenameValue] = useState<string>("");

  // (A) Called by context menu
  const handleRenameItem = (item: DriveItem) => {
    setContextMenu(null); // hide context menu
    setClickedItem(null);
    setRenameTarget(item);
    setRenameValue(item.name); // prefill with old name
  };

  // (B) Actually rename after user presses enter
  async function doRename(newName: string) {
    if (!renameTarget) return;
    // 1) Check duplicates in the current folder
    const nameExists = items.some(
      (i) => i.name === newName && i.id !== renameTarget.id
    );
    if (nameExists) {
      setErrorMessage(
        `A folder or file named "${newName}" already exists here.`
      );
      setRenameTarget(null);
      setRenameValue("");
      return;
    }

    // If it's a folder:
    if (renameTarget.isFolder) {
      try {
        if (!currentUser) return;
        const authToken = await currentUser.getIdToken();
        // Call your renameFolder service (example stub below).
        await renameFolder(renameTarget.id, newName, authToken);

        // Then refresh
        await refreshCurrentFolder();
      } catch (err) {
        console.error("Rename Folder error:", err);
      }
    } else {
      // If it’s a file => rename logic, e.g. renameFile(token, renameTarget.id, newName)
      try {
        if (!currentUser) return;
        const authToken = await currentUser.getIdToken();
        await renameFile(renameTarget.id, newName, authToken);

        // Then refresh
        await refreshCurrentFolder();
      } catch (err) {
        console.error("Rename File error:", err);
      }
    }

    // Clear out rename mode
    setRenameTarget(null);
    setRenameValue("");
  }

  function toggleCreateFolder() {
    setCreateFolderToggle((prev) => !prev);
    setUploadFileToggle(false);
  }
  function toggleUploadFile() {
    setUploadFileToggle((prev) => !prev);
    setCreateFolderToggle(false);
  }

  function handleContextMenuBackground(event: React.MouseEvent) {
    event.preventDefault();
    setContextMenu({ x: event.clientX, y: event.clientY });
    setClickedItem(null);
  }

  function handleContextMenuOnItem(event: React.MouseEvent, item: DriveItem) {
    event.stopPropagation();
    event.preventDefault();
    setContextMenu({ x: event.clientX, y: event.clientY });
    setClickedItem(item);
  }

  function handleClickAnywhere() {
    setContextMenu(null);
  }

  useEffect(() => {
    document.addEventListener("click", handleClickAnywhere);
    return () => {
      document.removeEventListener("click", handleClickAnywhere);
    };
  }, []);

  useEffect(() => {
    if (!currentUser) return;

    async function fetchRootOrFolder() {
      setLoading(true);
      try {
        if (currentUser) {
          const token = await currentUser.getIdToken();
          const { currentFolder, items } = await getUserFolderDataAsDriveItems(
            token,
            folderId ?? null
          );
          setCurrentFolder(currentFolder ?? null);
          setItems(items);
        }
      } catch (error) {
        console.error("Error fetching personal drive data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchRootOrFolder();
  }, [currentUser, folderId]);

  async function refreshCurrentFolder() {
    if (!currentUser) return;
    try {
      const token = await currentUser.getIdToken();
      const { currentFolder, items } = await getUserFolderDataAsDriveItems(
        token,
        folderId ?? null
      );
      setCurrentFolder(currentFolder ?? null);
      setItems(items);
    } catch (err) {
      console.error("Error refreshing after folder/file creation:", err);
    }
  }

  const handleDeleteItem = async (item: DriveItem) => {
    setContextMenu(null);
    if (item.isFolder) {
      if (!currentUser) return;

      try {
        const authToken = await currentUser.getIdToken();
        await removeFolder(authToken, item.id);
        refreshCurrentFolder(); // Refresh the folder view after successful deletion
      } catch (err: any) {

        // Set specific error messages based on the error type or message
        if (err.message.includes("Folder or its subfolders contain files")) {
          setErrorMessage(
            "The folder cannot be deleted because it contains files or subfolders."
          );
        } else if (err.message.includes("Folder not found")) {
          setErrorMessage(
            "The folder does not exist or you do not have permission to delete it."
          );
        } else {
          setErrorMessage(
            "An unexpected error occurred while trying to delete the folder. Please try again."
          );
        }
      }
    } else {
      if (!currentUser) return;

      try {
        const authToken = await currentUser.getIdToken();
        await removeFile(authToken, item.id);
        refreshCurrentFolder(); // Refresh the folder view after successful deletion
      } catch (err: any) {

        // Set specific error messages based on the error type or message
        if (err.message.includes("File not found")) {
          setErrorMessage(
            "The file does not exist or you do not have permission to delete it."
          );
        } else {
          setErrorMessage(
            "An unexpected error occurred while trying to delete the file. Please try again."
          );
        }
      }
      // e.g. confirm => call service => refresh
    }
  };

  const handleDownloadFile = async (item: DriveItem) => {
    try {
      if (currentUser) {
        const authToken: string = await currentUser.getIdToken();
        const blob = await downloadUserFile(Number(item.id), authToken);
        if (blob) {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = item.name; // Use the provided fileName
          document.body.appendChild(a);
          a.click();

          URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleViewFile = (item: DriveItem) => {
    setContextMenu(null);
    navigate(`/view/user/file/${item.id}`);
  };

  if (loading) return <div>Loading Personal Drive...</div>;

  const filtered = items.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Normal left-click
  function handleItemClick(item: DriveItem) {
    // If we’re currently renaming, we might exit rename mode here (optional).
    if (item.isFolder) {
      navigate(`/drive/personal/${item.id}`);
    } else {
      navigate(`/view/user/file/${item.id}`);
    }
  }

  function goUpOneLevel() {
    if (!currentFolder || currentFolder.parentFolderId == null) {
      navigate("/drive/personal");
    } else {
      navigate(`/drive/personal/${currentFolder.parentFolderId}`);
    }
  }
  const dismissBanner = () => {
    setErrorMessage("");
  };

  return (
    <div
      onContextMenu={handleContextMenuBackground}
      className="relative w-full h-full"
    >
      {contextMenu && (
        <CustomContextMenu
          x={contextMenu.x}
          y={contextMenu.y}
          clickedItem={clickedItem}
          toggleCreateFolder={toggleCreateFolder}
          toggleUploadFile={toggleUploadFile}
          onRename={handleRenameItem}
          onDelete={handleDeleteItem}
          onDownload={handleDownloadFile}
          onView={handleViewFile}
        />
      )}
      {errorMessage !== "" && (
        <div className="w-full mb-5 mx-auto">
          <Banner
            status="error"
            message={errorMessage}
            toggleBanner={dismissBanner}
            dismissBanner={dismissBanner}
          />
        </div>
      )}

      {createFolderToggle && (
        <div className="absolute bg-gray-100 right-0 w-[300px] h-[200px] rounded-lg shadow-lg p-5">
          <CreateFolder
            currentFolderId={currentFolder?.id}
            onFolderCreated={() => {
              toggleCreateFolder();
              refreshCurrentFolder();
            }}
            closeFolder={toggleCreateFolder}
          />
        </div>
      )}
      {uploadFileToggle && (
        <div className="absolute bg-gray-100 right-0 w-[300px] h-[200px] rounded-lg shadow-lg p-5">
          <UploadFile
            currentFolderId={currentFolder?.id}
            onFileUploaded={() => {
              toggleUploadFile();
              refreshCurrentFolder();
            }}
          />
        </div>
      )}

      {/* DRIVE HEADER AREA */}
      <div className="flex justify-between items-start align-middle">
        {currentFolder && currentFolder.parentFolderId !== null ? (
          <button
            className="border rounded-lg p-1 shadow-sm hover:shadow-none mb-2 text-xs bg-white"
            onClick={goUpOneLevel}
          >
            {"<"} Back
          </button>
        ) : (
          <div />
        )}

        <div className="flex-col flex justify-end">
          {/* SEARCH BOX */}
          <div className="flex justify-end">
            <DriveSearch
              onSearch={(query) => setSearchTerm(query)}
              placeholder="Search folders/files..."
            />
          </div>

          {/* Toggle for grid/table */}
          <div className="flex gap-2 mb-2 justify-end">
            Display:
            <button
              className={
                viewMode === "grid"
                  ? "bg-[#3DD598] text-white w-7 h-7"
                  : "bg-gray-200 w-7 h-7"
              }
              onClick={() => setViewMode("grid")}
            >
              <FontAwesomeIcon icon={faGripVertical} />
            </button>
            <button
              className={
                viewMode === "table"
                  ? "bg-[#3DD598] text-white w-7 h-7"
                  : "bg-gray-200 w-7 h-7"
              }
              onClick={() => setViewMode("table")}
            >
              <FontAwesomeIcon icon={faList} />
            </button>
          </div>
        </div>
      </div>

    <div className="max-h-[500px] overflow-y-auto">
      <h2 className="text-text inter-font font-bold text-xl mb-3">
        My Drive: {currentFolder?.name ? ` / ${currentFolder.name}` : ""}
      </h2>

      {/* PASS rename info into the list, so that FolderItem/FileItem can know if they're being renamed */}
      <DriveList
        items={filtered}
        viewMode={viewMode}
        onItemClick={handleItemClick}
        onRightClickItem={handleContextMenuOnItem}
        // We'll pass rename-related props, so that each item can see if it's the rename target
        renameTarget={renameTarget}
        renameValue={renameValue}
        setRenameValue={setRenameValue}
        doRename={doRename}
      />
      {filtered.length === 0 && (
        <div className="text-muted text-sm text-center mt-5">There are currently no files or folders in this directory</div>
      )}
    </div>
    </div>
  );
}

export default PersonalDrive;
