import React from "react";

const PageNotFound: React.FC = () => {
  return (
    <div className="relative bg-white dark:bg-background-dark sm:min-w-[640px] overflow-x-auto max-w-full h-screen">
      <div className="bg-black w-full h-[300px] rounded-b-3xl"></div>
      <div className="overflow-x-auto flex justify-center items-center align-middle -top-64 relative dark:bg-card-dark dark:text-text-dark bg-[#dedbd1] p-5 rounded-3xl shadow-lg w-11/12 mx-auto min-h-[600px] -mt-10">
          <img
            src="/site-images/not_found_image.png"
            className="w-1/2 h-full"
            alt="Background"
          />
          <div className="max-w-[300px]">
            <div className="text-9xl font-bold">404</div>
            <div className="inter-font">Oops! It looks like the page you are looking for can't be found!</div>
          </div>
        </div>
      </div>
  );
};

export default PageNotFound;
