import AdministrativeTransactionRequest from "../../interfaces/adminRequests/AdministrativeTransactionRequest";
import CreateAdministrativeTransactionRequest from "../../interfaces/adminRequests/CreateAdministrativeTransactionRequest";

// Function to fetch administrative transaction requests for a user
export async function getAdministrativeTransactionRequestsForUser(
  authToken: string,
  userId: string
): Promise<AdministrativeTransactionRequest[] | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/admin/request`;

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.error("Error fetching administrative transaction requests:", response.statusText);
      return null;
    }

    const res = await response.json();

    const requests: AdministrativeTransactionRequest[] = res.map((request: any) => ({
      id: request.id,
      requesterName: request.requesterName,
      approverName: request.approverName || null,
      transactionType: request.transactionType,
      status: request.status,
      requestTime: request.requestTime,
      taskId: request.taskId,
      templateTaskId: request.templateTaskId, 
      taskTitle: request.taskTitle,
      completionTime: request.completionTime || null,
    }));

    return requests;
  } catch (error) {
    console.error("Error fetching administrative transaction requests:", error);
    return null;
  }
}

// Function to create a new administrative transaction request
export async function createAdministrativeTransactionRequest(
  authToken: string,
  newRequest: CreateAdministrativeTransactionRequest
): Promise<AdministrativeTransactionRequest | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/admin/request`;

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRequest),
    });

    if (!response.ok) {
      console.error("Error creating administrative transaction request:", response.statusText);
      return null;
    }

    const res = await response.json();

    const createdRequest: AdministrativeTransactionRequest = {
      id: res.id,
      requesterName: res.requesterName,
      approverName: res.approverName || null,
      transactionType: res.transactionType,
      status: res.status,
      requestTime: res.requestTime,
      completionTime: res.completionTime || null,
      taskId: res.taskId || null,
      templateTaskId: res.templateTaskId || null,
      taskTitle: res.taskTitle || null
    };

    return createdRequest;
  } catch (error) {
    console.error("Error creating administrative transaction request:", error);
    return null;
  } 
}

// Function to approve an existing administrative transaction request
export async function approveAdministrativeTransactionRequest(
  authToken: string,
  requestId: number
): Promise<boolean> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/admin/request/approve/${requestId}`;

    const response = await fetch(apiUrl, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.error("Error approving administrative transaction request:", response.statusText);
      return false;
    }

    // If the API returns any JSON, you can handle it here. Otherwise, just return true.
    return true;
  } catch (error) {
    console.error("Error approving administrative transaction request:", error);
    return false;
  }
}

// Function to reject an existing administrative transaction request
export async function rejectAdministrativeTransactionRequest(
  authToken: string,
  requestId: number
): Promise<boolean> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/admin/request/reject/${requestId}`;

    const response = await fetch(apiUrl, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.error("Error rejecting administrative transaction request:", response.statusText);
      return false;
    }

    return true;
  } catch (error) {
    console.error("Error rejecting administrative transaction request:", error);
    return false;
  }
}