// file: components/chat/ChatWindow.tsx
import React, { useEffect, useState, useRef } from "react";
import { useChatConnection } from "../../../contexts/ChatConnectionContext";
import {
  Conversation,
  Message,
  getMessagesForConversation,
} from "../../../services/chatService/ChatService";
import { getTimeAgo } from "../../../utilities/dateUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

interface ChatWindowProps {
  authToken: string;
  currentUserId: string;
  conversation: Conversation;
  sendMessageFn: (input: string, conversationId: number) => void;
}

const ChatWindow: React.FC<ChatWindowProps> = ({
  authToken,
  currentUserId,
  conversation,
  sendMessageFn,
}) => {
  // 1) The shared connection
  const connection = useChatConnection();

  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [localText, setLocalText] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [typingUsers, setTypingUsers] = useState<string[]>([]);

  // For autoscroll
  const conversationBodyRef = useRef<HTMLDivElement>(null);

  // =============== LOAD MESSAGES ===============
  useEffect(() => {
    const loadMessages = async () => {
      setLoading(true);
      try {
        const data = await getMessagesForConversation(authToken, conversation.id);
        setMessages(data);
      } catch (err) {
        console.error("Error loading messages:", err);
      } finally {
        setLoading(false);
      }
    };

    if (conversation.id) {
      loadMessages();
    }
  }, [authToken, conversation.id]);

  // =============== JOIN + EVENT HANDLERS ===============
  useEffect(() => {
    if (!connection || !conversation.id) return;

    // 1) Join conversation group
    connection
      .invoke("JoinConversation", conversation.id)
      .then(() => {
        // Mark it read
        return connection.invoke("MarkConversationRead", conversation.id);
      })
      .catch((err) => console.error("JoinConversation error:", err));

    // 2) "ReceiveMessage"
    const handleReceiveMessage = (msg: Message) => {
      // if msg.conversationId === conversation.id, push into messages
      if (msg.conversationId !== conversation.id) return;
      setMessages((prev) => [...prev, msg]);
    };
    connection.on("ReceiveMessage", handleReceiveMessage);

    // 3) "ReceiveTypingStatus"
    const handleTypingStatus = (
      convId: number,
      typingUserId: string,
      currentlyTyping: boolean
    ) => {
      if (convId !== conversation.id) return;
      if (typingUserId === currentUserId) return;
      setTypingUsers((prev) => {
        if (currentlyTyping) {
          if (!prev.includes(typingUserId)) return [...prev, typingUserId];
          return prev;
        } else {
          return prev.filter((id) => id !== typingUserId);
        }
      });
    };
    connection.on("ReceiveTypingStatus", handleTypingStatus);

    // 4) Optionally "ReceiveReadReceipt" if you want to show "Seen" status in ChatWindow
    // (not strictly necessary if only ConversationList uses it)

    // Cleanup:
    return () => {
      connection.off("ReceiveMessage", handleReceiveMessage);
      connection.off("ReceiveTypingStatus", handleTypingStatus);
      // Optionally leave the group
      connection
        .invoke("LeaveConversation", conversation.id)
        .catch(console.error);
    };
  }, [connection, conversation.id, currentUserId]);

  // =============== TYPING HANDLING ===============
  const handleLocalInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalText(e.target.value);
    if (!isTyping && connection) {
      setIsTyping(true);
      connection
        .invoke("SendTypingStatus", conversation.id, true)
        .catch(console.error);
    }
  };

  // If user hasn't typed for 2s, send "stopped typing"
  useEffect(() => {
    if (!isTyping) return;
    const timer = setTimeout(() => {
      setIsTyping(false);
      if (connection) {
        connection
          .invoke("SendTypingStatus", conversation.id, false)
          .catch(console.error);
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [isTyping, connection, conversation.id]);

  // Autoscroll
  useEffect(() => {
    if (conversationBodyRef.current) {
      conversationBodyRef.current.scrollTop =
        conversationBodyRef.current.scrollHeight;
    }
  }, [messages]);

  // For a group, you might show all participants up top. For a 1-on-1, just show the other user.
  const participantList = conversation.participants
    .filter((participant) => participant.id !== currentUserId)
    .map((p, index) => {
      return p.displayName || p.firstName + " " + p.lastName;
    })
    .join(", ");

  return (
    <div
      style={{ flex: 1, display: "flex", flexDirection: "column" }}
      className="bg-[#F7F9FC] h-[75vh]"
    >
      {/* Show participants */}
      <div
        className="bg-white p-2 py-4"
        style={{
          borderBottom: "1px solid #ccc",
          fontWeight: "bold",
        }}
      >
        Conversation with {participantList}
      </div>

      {/* Messages */}
      <div
        className="p-2"
        ref={conversationBodyRef}
        style={{
          flex: 1,
          overflowY: "auto",
          margin: "0.5rem 0",
        }}
      >
        {loading ? (
          <div>Loading messages...</div>
        ) : (
          <div style={{ padding: "8px" }}>
            {messages.map((msg) => {
              const isMine = msg.author.id === currentUserId;
              const isGroup =
                conversation.participants.filter((p) => p.id !== currentUserId)
                  .length > 1;
              return (
                <div
                  key={msg.id}
                  style={{
                    margin: "4px 0",
                    textAlign: isMine ? "right" : "left",
                  }}
                >
                  <strong className="block">
                    {msg.author.id !== currentUserId &&
                      isGroup &&
                      (msg.author.displayName ||
                        msg.author.firstName + " " + msg.author.lastName)}
                  </strong>
                  <div
                    className={`rounded-xl text-start border shadow-md minw-2/3 max-w-[600px] ${
                      isMine ? "bg-blue-500 text-white ms-20" : "bg-white me-20"
                    }`}
                    style={{
                      display: "inline-block",
                      padding: "6px 10px",
                    }}
                  >
                    <div>{msg.messageContent}</div>
                  </div>
                  <div
                    className="mt-1"
                    style={{ fontSize: "0.8em", color: "#888" }}
                  >
                    {getTimeAgo(new Date(msg.createDate))}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {/* Input */}
      <div className="flex flex-col relative">
        <div className="absolute -top-8">
          {typingUsers.length > 0 && (
            <div
              className="mb-2"
              style={{ fontStyle: "italic", color: "#555", margin: "0.5rem" }}
            >
              {typingUsers.length === 1
                ? `${
                    conversation.participants.filter(
                      (participant) => participant.id === typingUsers[0]
                    )[0].firstName
                  } is typing...`
                : "Multiple people are typing..."}
            </div>
          )}
        </div>
        <div className="flex" style={{ padding: "0.5rem" }}>
          <input
            className="rounded-3xl"
            style={{ flex: 1, marginRight: "0.5rem" }}
            placeholder="Type a message..."
            value={localText}
            onChange={handleLocalInput}
            onKeyDown={(e) => {
              // Check if the Enter key was pressed
              if (e.key === "Enter" && localText.trim().length > 0) {
                sendMessageFn(localText.trim(), conversation.id);
                setLocalText("");
              }
            }}
          />
          <div className="rounded-full p-2 w-10 h-10 flex items-center align-middle justify-center bg-blue-500 text-white">
            <button
              onClick={() => {
                if (localText.trim().length > 0) {
                  sendMessageFn(localText.trim(), conversation.id);
                  setLocalText("");
                }
              }}
            >
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatWindow;
