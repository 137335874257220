import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DriveList from "../driveItems/DriveList";
import { DriveItem } from "../../../../../interfaces/caseFileDrive/DriveFiles";
import DriveSearch from "../driveItems/DriveSearch";
import { useDriveContext } from "../../../../../contexts/DriveContext";
import { useAuth } from "../../../../../firebase/AuthProvider";
import { getUserFolderDataAsDriveItems } from "../../../../../services/fileServices/FileService"; 
import CustomContextMenu from "../userDrive/RightClickComponent";
import { UploadFile } from "../userDrive/UploadFile";
// If you have a "CreateFolder" component, import that too, or handle inline.

interface CurrentFolder {
  id: number;
  name: string;
  parentFolderId: number | null;
}

function PersonalFolder() {
  const { currentUser } = useAuth();
  const { folderId } = useParams();
  const navigate = useNavigate();
  const { viewMode, setViewMode } = useDriveContext();

  const [currentFolder, setCurrentFolder] = useState<CurrentFolder | null>(null);
  const [items, setItems] = useState<DriveItem[]>([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);

  // 1) State for Right-Click Menu position
  const [contextMenu, setContextMenu] = useState<{ x: number; y: number } | null>(null);

  // 2) Toggles for create folder / upload file
  const [createFolderToggle, setCreateFolderToggle] = useState<boolean>(false);
  const [uploadFileToggle, setUploadFileToggle] = useState<boolean>(false);

  // Functions to flip toggles
  function toggleCreateFolder() {
    setCreateFolderToggle((prev) => !prev);
    // Possibly also close the upload toggle
    setUploadFileToggle(false);
  }
  function toggleUploadFile() {
    setUploadFileToggle((prev) => !prev);
    // Possibly also close the create folder toggle
    setCreateFolderToggle(false);
  }

  // 3) Right-click event handler
  function handleContextMenu(event: React.MouseEvent) {
    event.preventDefault();
    setContextMenu({ x: event.clientX, y: event.clientY });
  }

  // 4) Click anywhere hides the context menu
  function handleClickAnywhere() {
    setContextMenu(null);
  }

  useEffect(() => {
    document.addEventListener("click", handleClickAnywhere);
    return () => {
      document.removeEventListener("click", handleClickAnywhere);
    };
  }, []);

  // --- Folder data fetch
  useEffect(() => {
    if (!currentUser || !folderId) return;
    async function fetchFolderData() {
      try {
        if (currentUser && folderId) { 
            setLoading(true);
            const token = await currentUser.getIdToken();
            // This function returns { currentFolder, items }
            const { currentFolder, items } = await getUserFolderDataAsDriveItems(token, folderId);
            setCurrentFolder(currentFolder ?? null);
            setItems(items);
        }
      } catch (error) {
        console.error("Error fetching folder data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchFolderData();
  }, [currentUser, folderId]);

  // Filter
  const filtered = items.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) return <div>Loading...</div>;

  function handleItemClick(item: DriveItem) {
    if (item.isFolder) {
      navigate(`/drive/personalDrive/${item.id}`);
    } else {
      // e.g. navigate(`/view/user/file/${item.id}`) if desired
    }
  }

  // Up one level
  function goUpOneLevel() {
    if (!currentFolder || currentFolder.parentFolderId == null) {
      navigate("/drive/personalDrive");
    } else {
      navigate(`/drive/personalDrive/${currentFolder.parentFolderId}`);
    }
  }

  // OPTIONAL: Handle refresh after creating folder / uploading file
  // For instance, once a folder is created or file is uploaded,
  // you can re-fetch the contents of the current folder:
  async function refreshCurrentFolder() {
    try {
      if (currentUser && folderId) {
        const token = await currentUser.getIdToken();
        const { currentFolder, items } = await getUserFolderDataAsDriveItems(token, folderId);
        setCurrentFolder(currentFolder ?? null);
        setItems(items);
      }
    } catch (error) {
      console.error("Error refreshing folder data:", error);
    }
  }

  return (
    <div 
      className="relative w-full h-full"
      onContextMenu={handleContextMenu}   
    >
      {/* If contextMenu is not null, show your custom context menu */}
      {/* {contextMenu && (
        <CustomContextMenu
          x={contextMenu.x}
          y={contextMenu.y}
          toggleCreateFolder={toggleCreateFolder}
          toggleUploadFile={toggleUploadFile}
        />
      )} */}

      <div className="flex justify-between">
        <button onClick={goUpOneLevel}>{"< Up"}</button>

        <div className="flex">
          <DriveSearch
            onSearch={(query) => setSearchTerm(query)}
            placeholder="Search in folder..."
          />
          <div className="flex gap-2 ml-4">
            <button
              className={
                viewMode === "grid"
                  ? "bg-[#3DD598] text-white w-7 h-7"
                  : "bg-gray-200 w-7 h-7"
              }
              onClick={() => setViewMode("grid")}
            >
              Grid
            </button>
            <button
              className={
                viewMode === "table"
                  ? "bg-[#3DD598] text-white w-7 h-7"
                  : "bg-gray-200 w-7 h-7"
              }
              onClick={() => setViewMode("table")}
            >
              Table
            </button>
          </div>
        </div>
      </div>

      {/* Display folder name */}
      <h2 className="text-xl font-bold mb-3">
        {currentFolder
          ? `Folder: ${currentFolder.name}`
          : folderId
          ? `Folder ${folderId}`
          : "Root Folder"}
      </h2>

      {/* Optional "Create Folder" section or modal */}
      {createFolderToggle && (
        <div className="my-4 p-4 border rounded">
          <h4>Create Folder</h4>
          {/* 
            Put your form or a <CreateFolder> component here.
            On success, call refreshCurrentFolder() to show new folder.
          */}
          <button onClick={toggleCreateFolder}>Close</button>
        </div>
      )}

      {/* Optional "Upload File" section or modal */}
      {uploadFileToggle && (
        <div className="my-4 p-4 border rounded">
          <h4>Upload File</h4>
          <UploadFile
            currentFolderId={currentFolder?.id} 
            onFileUploaded={() => {
              // once file is uploaded, refresh
              refreshCurrentFolder();
            }}
          />
          <button onClick={toggleUploadFile}>Close</button>
        </div>
      )}

      {/* Main Drive List */}
      <DriveList
        items={filtered}
        viewMode={viewMode}
        onItemClick={handleItemClick}
        onRightClickItem={() => {}}
      />
    </div>
  );
}

export default PersonalFolder;
