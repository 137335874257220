import React from "react";
import { useNavigate } from "react-router-dom";

const UserProfileMessages: React.FC = () => { 
    const navigate = useNavigate();
    return <div>
        <div>User Profile Messages coming soon...</div>
        <div>Until then, feel free to click <span className="text-blue-500 cursor-pointer" onClick={() => navigate('/chat')}>here</span> to go to your conversations</div>
        </div>
};

export default UserProfileMessages;
