import React, { createContext, useContext, useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";
import { useAuth } from "../firebase/AuthProvider";

/**
 * The shape of our context: a single HubConnection or null.
 */
const ChatConnectionContext = createContext<signalR.HubConnection | null>(null);

interface ChatConnectionProviderProps {
  children: React.ReactNode;
}

export const ChatConnectionProvider: React.FC<ChatConnectionProviderProps> = ({
  children,
}) => {
  const { currentUser } = useAuth();
  const [connection, setConnection] = useState<signalR.HubConnection | null>(
    null
  );

  useEffect(() => {
    if (!currentUser) {
      // If we have no token, we cannot connect. Optionally setConnection(null).
      return;
    }
    const getNewConn = async () => {
      const authToken: string = await currentUser.getIdToken();
      // Build and start the SignalR connection:
      const newConn = new signalR.HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_API_BASE_URL}/chatHub`, {
          accessTokenFactory: () => authToken,
        })
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();

      newConn
        .start()
        .then(() => {
          console.log("[Global] SignalR connection started.");
          setConnection(newConn);
        })
        .catch((err) => {
          console.error("[Global] Failed to connect SignalR:", err);
        });

      // Cleanup: on unmount or token change, stop the connection
      return () => {
        newConn
          .stop()
          .then(() => console.log("[Global] SignalR connection stopped."))
          .catch(console.error);
      };
    };

    getNewConn();
  }, [currentUser]);

  return (
    <ChatConnectionContext.Provider value={connection}>
      {children}
    </ChatConnectionContext.Provider>
  );
};

/**
 * A small helper hook that returns our global chat connection (or null).
 */
export function useChatConnection() {
  return useContext(ChatConnectionContext);
}
