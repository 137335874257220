import {
    faArrowUpFromBracket,
    faDownload,
    faEye,
    faFileAudio,
    faFileExcel,
    faFileImage,
    faFilePdf,
    faFileWord,
    faTrash,
    faX,
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import React, { useState } from "react";
  import { useNavigate } from "react-router-dom";
  import { AttachmentState } from "../../../../interfaces/task/TaskFiles/AttachmentState";
  import FileActionModal from "./FileActionModals/FileActionModal";
  import { useAuth } from "../../../../firebase/AuthProvider";
  import {
    deleteFileById,
    downloadFile,
  } from "../../../../services/fileServices/FileService";
  import FileUploadPopUp from "../../fileUploadComponent/FileUploadPopUp";
  
  interface FileCardProps {
    id: string;
    attachmentId: number;
    filetype: string;
    filename: string;
    author: string;
    lastUpdated: string;
    createDate: string;
    location: string;
    state: AttachmentState;
    reload: () => void;
    canUserDeleteFile: boolean;
    taskId: string;
  }
  
  const FileCard: React.FC<FileCardProps> = ({
    filetype,
    filename,
    attachmentId,
    location,
    author,
    lastUpdated,
    createDate,
    id,
    state,
    reload,
    canUserDeleteFile,
    taskId,
  }) => {
    const { currentUser } = useAuth();
    const [hovered, setHovered] = useState(false);
    const [showActions, setShowActions] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadFileToggle, setUploadFileToggle] = useState<boolean>(false);
    const navigate = useNavigate();
  
    const handleMouseEnter = () => {
      setHovered(true);
    };
  
    const handleMouseLeave = () => {
      setHovered(false);
    };
  
    const handleView = () => {
      navigate(`/view/file/${id}`);
    };
  
    const handleDownload = async () => {
      try {
        if (currentUser) {
          const authToken: string = await currentUser.getIdToken();
          const blob = await downloadFile(id, authToken);
          if (blob) {
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = filename; // Use the provided fileName
            document.body.appendChild(a);
            a.click();
  
            URL.revokeObjectURL(url);
            document.body.removeChild(a);
          }
        }
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    };
  
    const handleNewUpload = () => {
      setUploadFileToggle(!uploadFileToggle);
    };
  
    const handleDelete = () => setShowModal(true);
  
    const confirmDelete = async () => {
      setIsLoading(true);
      try {
        if (currentUser) {
          const authToken: string = await currentUser.getIdToken();
          await deleteFileById(id, authToken);
          // Assuming delete is successful
          setIsLoading(false);
          setShowModal(false);
          reload();
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error deleting the file:", error);
      }
    };
  
    return (
      <div
        className="card bg-card dark:bg-card-dark text-text dark:text-text-dark cursor-pointer flex 
            hover:shadow-lg rounded-lg relative"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => setShowActions(!showActions)}
      >
        {uploadFileToggle && (
          <div className="absolute right-0 z-50  flex align-center items-center gap-10 w-full h-full">
            <div className="bg-muted p-5 rounded-full w-10 h-10 flex items-center justify-center cursor-pointer hover:bg-muted-dark" onClick={handleNewUpload}>
              <FontAwesomeIcon icon={faX} />
            </div>
            <div>
              <FileUploadPopUp
                taskId={taskId}
                toggleFileUpload={handleNewUpload}
                attachmentId={attachmentId}
              />
            </div>
          </div>
        )}
        <div className="flex w-40">
          {state.state === "IN REVIEW" && (
            <div className="rounded-l-lg border-l-lg bg-warning w-1 h-full"></div>
          )}
          {state.state === "ISSUES FOUND" && (
            <div className="rounded-l-lg border-l-lg bg-danger w-1 h-full"></div>
          )}
          {state.state === "READY" && (
            <div className="rounded-l-lg border-l-lg bg-ready w-1 h-full"></div>
          )}
          <div className="flex p-2">
            {filetype === "PDF" && (
              <div className="mx-2 text-red-500 text-md">
                <FontAwesomeIcon icon={faFilePdf} />
              </div>
            )}
            {filetype === "Image" && (
              <div className="mx-2 text-purple-700 text-md">
                <FontAwesomeIcon icon={faFileImage} />
              </div>
            )}
            {filetype === "Audio" && (
              <div className="mx-2 text-cyan-800 text-md">
                <FontAwesomeIcon icon={faFileAudio} />
              </div>
            )}
            {filetype === "Excel" && (
              <div className="mx-2 text-green-500 text-md">
                <FontAwesomeIcon icon={faFileExcel} />
              </div>
            )}
            {filetype === "Word" && (
              <div className="mx-2 text-blue-500 text-md">
                <FontAwesomeIcon icon={faFileWord} />
              </div>
            )}
            <div className="me-2 h-full truncate max-w-24">{filename}</div>
          </div>
        </div>
        {hovered && !showActions && (
          <div
            className="tooltip absolute bg-card dark:bg-card-dark text-text dark:text-text-dark p-2 rounded shadow-lg text-xs max-w-80 min-w-full w-auto z-40 whitespace-nowrap"
            style={{ top: "120%", left: "50%", transform: "translateX(-50%)" }}
          >
            <div className="flex justify-between truncate">
              <p className="font-bold">File name: &nbsp;</p> {filename}
            </div>
            <div className="flex justify-between truncate">
              <p className="font-bold">File Type: &nbsp;</p> {filetype}
            </div>
            <div className="flex justify-between truncate">
              <p className="font-bold">Author: &nbsp;</p> {author}
            </div>
            <div className="flex justify-between truncate">
              <p className="font-bold">Created: &nbsp;</p> {createDate}
            </div>
            <div className="flex justify-between truncate">
              <p className="font-bold">Updated: &nbsp;</p> {lastUpdated}
            </div>
          </div>
        )}
        {showModal && (
          <FileActionModal
            onConfirm={confirmDelete}
            onCancel={() => setShowModal(false)}
            isLoading={isLoading}
          >
            Are you sure you want to delete this file?
          </FileActionModal>
        )}
        {showActions && (
          <div
            className="absolute bg-white dark:bg-gray-700 text-black dark:text-white p-2 rounded shadow-lg text-xs z-50"
            style={{
              top: "100%",
              left: "50%",
              transform: "translateX(-50%) translateY( 10%)",
            }}
          >
            <div
              onClick={handleView}
              className="flex items-center hover:bg-primary hover:text-white p-1 rounded cursor-pointer"
            >
              <FontAwesomeIcon icon={faEye} className="mr-2" /> View
            </div>
            <div
              onClick={handleDownload}
              className="flex items-center mt-2 hover:bg-primary hover:text-white p-1 rounded cursor-pointer"
            >
              <FontAwesomeIcon icon={faDownload} className="mr-2" /> Download
            </div>
            {canUserDeleteFile && (
              <div
                onClick={handleNewUpload}
                className="flex items-center mt-2 hover:bg-primary hover:text-white p-1 rounded cursor-pointer"
              >
                <FontAwesomeIcon icon={faArrowUpFromBracket} className="mr-2" />{" "}
                New Draft
              </div>
            )}
            {state.state !== "COMPLETED" && canUserDeleteFile && (
              <div
                onClick={handleDelete}
                className="flex items-center mt-2 hover:bg-danger hover:text-white p-1 rounded cursor-pointer"
              >
                <FontAwesomeIcon icon={faTrash} className="mr-2" /> Delete
              </div>
            )}
          </div>
        )}
      </div>
    );
  };
  
  export default FileCard;