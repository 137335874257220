// FileUpload.tsx

// This file should be reserved for use with known file names, Required File Types

import React, { useState } from "react";
import { useAuth } from "../../../firebase/AuthProvider";
import { uploadRequiredFile } from "../../../services/fileServices/FileService";
import { TaskRequiresAttachment } from "../../../interfaces/task/TaskFiles/TaskRequiresAttachment";

interface FileUploadProps {
  toggleFileUpload: (success: boolean) => void;
  taskId: string;
  attachment: TaskRequiresAttachment;
}

const FileUpload: React.FC<FileUploadProps> = ({
  toggleFileUpload,
  taskId,
  attachment,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [durationInMinutes, setDurationInMinutes] = useState<string>("0");

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDurationInMinutes(e.target.value);
  };

  const handleUpload = async () => {
    setIsLoading(true);

    // Handle upload logic here
    if (selectedFile && durationInMinutes) {
      if (currentUser !== null) {
        const authToken: string = await currentUser.getIdToken();

        // Extract file metadata
        const fileSize = selectedFile.size;
        const mimeType = selectedFile.type || "application/octet-stream"; // Fallback for unknown MIME types

        await uploadRequiredFile(
          selectedFile,
          taskId,
          attachment,
          durationInMinutes,
          mimeType,
          fileSize,
          authToken
        ).then((success) => {
          if (success) {
            toggleFileUpload(true);
            setIsLoading(false);
          } else {
            console.error("Error uploading file.");
            setIsLoading(false);
          }
        });
      }
      // You can perform file upload operations here
    } else {
      console.error("No file selected");
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full flex items-center justify-center">
      <div className="bg-card dark:bg-card-dark shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <label
            className="block text-text dark:text-text-dark text-sm font-bold mb-2"
            htmlFor="fileUpload"
          >
            Time Spent (in minutes):
          </label>
          <input
            id="fileUpload"
            type="number"
            min={0}
            defaultValue={
              attachment.templateFile.estimatedTtc
                ? attachment.templateFile.estimatedTtc
                : 0
            }
            accept=".jpg,.jpeg,.png,.gif,.pdf"
            onChange={handleNumberChange}
            className="appearance-none border rounded w-full py-2 px-3 text-text dark:text-text-dark leading-tight focus:outline-none focus:shadow-outline file:mr-4 file:rounded-md file:border-0 file:bg-primary-500 file:py-2.5 file:px-4 file:text-sm file:font-semibold file:text-text"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-text dark:text-text-dark text-sm font-bold mb-2"
            htmlFor="fileUpload"
          >
            Select a file:
          </label>
          <input
            id="fileUpload"
            type="file"
            accept=".jpg,.jpeg,.png,.gif,.pdf"
            onChange={handleFileChange}
            className="appearance-none border rounded w-full py-2 px-3 text-text dark:text-text-dark leading-tight focus:outline-none focus:shadow-outline file:mr-4 file:rounded-md file:border-0 file:bg-primary-500 file:py-2.5 file:px-4 file:text-sm file:font-semibold file:text-text"
          />
        </div>
        <div className="flex flex-col items-center justify-between">
          {isLoading ? (
            <button className="bg-[#61ffc0] w-full rounded-xl text-text-dark font-bold py-2 px-4 focus:outline-none focus:shadow-outline">
              Upload
            </button>
          ) : (
            <button
              onClick={handleUpload}
              className="bg-[#3dd598] w-full rounded-xl text-text-dark font-bold py-2 px-4 focus:outline-none focus:shadow-outline"
            >
              Upload
            </button>
          )}
          {selectedFile && (
            <div className="text-gray-700 text-sm">{selectedFile.name}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
