// Dashboard.tsx
import React, { useEffect } from "react";
import { useAuth } from "../../../firebase/AuthProvider";
import Tasks from "../tasks/Tasks";
import AdminDashboard from "./AdminDashboard";

const Dashboard: React.FC = () => {
  const { currentRoles } = useAuth();

  useEffect(() => {
    if (currentRoles) {
    }
  }, [currentRoles]);

  return (
    <div className="relative bg-background dark:bg-background-dark sm:min-w-[640px] overflow-x-hidden max-w-full flex-grow flex-wrap">
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>
      {currentRoles?.some((role) => role.name === "ADMIN") ? (
        <div className="relative -top-20">
          <div className="">
            <AdminDashboard />
          </div>
          <div className="relative flex flex-wrap flex-grow bg-[#F7F9FC] dark:bg-[#141416] h-auto">
            <div className="relative mx-auto min-h-full flex flex-wrap flex-grow h-auto ">
              <Tasks />
            </div>
          </div>
        </div>
      ) : (
        <div className="relative flex flex-wrap flex-grow bg-[#F7F9FC] dark:bg-[#141416] h-auto">
          <div className="relative mx-auto min-h-full flex flex-wrap flex-grow h-auto -top-40">
            <Tasks />
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;