// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import {
//   getDriveFileById,
//   getFileById,
// } from "../../../services/fileServices/FileService";
// import { useAuth } from "../../../firebase/AuthProvider";
// import DriveFile from "../../../interfaces/file/DriveFile";
// import PDFViewer from "./pdfViewer/PDFViewer"; // Your custom PDFViewer
// import FileViewer from "react-file-viewer"; // Import react-file-viewer

// const FileViewPage: React.FC = () => {
//   const { currentUser } = useAuth();
//   const { fileId } = useParams();
//   const [fileUrl, setFileUrl] = useState<string | null>(null); // To store the blob URL
//   const [fileMetadata, setFileMetadata] = useState<DriveFile>();
//   const [blobInferredMimeType, setBlobInferredMimeType] = useState<string>("");
//   const [scale, setScale] = useState(1.0); // State for controlling the zoom level
//   const [zoom, setZoom] = useState<number>(1.0); // Zoom state for image files

//   useEffect(() => {
//     const fetchFile = async () => {
//       if (currentUser && fileId) {
//         try {
//           const authToken = await currentUser.getIdToken();
//           const file: DriveFile | null = await getDriveFileById(
//             fileId,
//             authToken
//           );

//           if (file) {
//             setFileMetadata(file);

//             const fileUrl = await getFileById(fileId, authToken);
//             if (fileUrl) {
//               setFileUrl(fileUrl); // Set the file URL if it's valid

//               // Fetch the blob and check its MIME type
//               const response = await fetch(fileUrl);
//               const blob = await response.blob();

//               // Infer correct MIME type based on file extension
//               let inferredMimeType = file.mimeType || blob.type;
//               if (file.mimeType === null && blob.type === "application/octet-stream") {
//                 const extension = file.fileName
//                   ?.split(".")
//                   .pop()
//                   ?.toLowerCase();
//                 switch (extension) {
//                   case "pdf":
//                     inferredMimeType = "application/pdf";
//                     break;
//                   case "jpg":
//                   case "jpeg":
//                     inferredMimeType = "image/jpeg";
//                     break;
//                   case "png":
//                   case "image/png":
//                     inferredMimeType = "image/png";
//                     break;
//                   case "doc":
//                   case "docx":
//                     inferredMimeType =
//                       "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
//                     break;
//                   // Add more cases as needed for other file types
//                   default:
//                     inferredMimeType = "application/octet-stream";
//                 }
//               }

//               setBlobInferredMimeType(inferredMimeType);
//             }
//           }
//         } catch (error) {
//           console.error("Error fetching file:", error);
//         }
//       }
//     };

//     fetchFile();
//   }, [currentUser, fileId]);

//   // Define renderViewer function
//   const renderViewer = () => {
//     if (!fileUrl) return <div>Loading...</div>;

//     const fileType = (() => {
//       if (blobInferredMimeType === "application/pdf") return "pdf";
//       if (
//         blobInferredMimeType ===
//         "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
//       )
//         return "docx";
//       if (blobInferredMimeType === "image/jpeg") return "jpg";
//       if (blobInferredMimeType === "image/png") return "png";

//       const extension = fileMetadata?.fileName?.split(".").pop()?.toLowerCase();
//       if (extension === "pdf") return "pdf";
//       if (extension === "doc" || extension === "docx") return "docx";
//       if (extension === "jpg" || extension === "jpeg") return "jpg"; // Use 'jpg' for both
//       if (extension === "png") return "png";
//       console.log(fileMetadata?.type.type)
//       if (fileMetadata?.type.type.toLowerCase() === 'image') return "png"

//       return "unknown";
//     })();

//     const viewerStyles = {
//       transform: `scale(${zoom})`,
//       transformOrigin: "top left",
//       width: "100%",      // Ensure the viewer takes full width
//       height: "100%",     // Ensure the viewer takes full height
//       overflow: "hidden", // Hide scrollbars
//     };

//     if (fileType === "pdf") {
//       // Use custom PDFViewer for PDFs with access to scale
//       return <PDFViewer fileUrl={fileUrl} scale={scale} />;
//     } else if (fileType === "jpg" || fileType === "png") {
//       // Use an img tag to render images with zoom controls
//       return (
//         <div className="file-viewer-container">
//           <div className="image-container" style={viewerStyles}>
//             <img src={fileUrl} alt={fileMetadata?.fileName} style={{ maxWidth: "100%", height: "auto", transform: `scale(${zoom})`, transformOrigin: "center" }} />
//           </div>
//         </div>
//       );
//     } else {
//       // Use react-file-viewer for other file types
//       return (
//         <FileViewer
//           fileType={fileType}
//           filePath={fileUrl}
//           errorComponent={() => <div>Error loading file</div>}
//           onError={(error) => console.log("Error while viewing file:", error)}
//         />
//       );
//     }
//   };

//   return (
//     <div className="relative bg-background dark:bg-background-dark sm:min-w-[640px] overflow-x-auto max-w-full flex-grow flex-wrap h-screen">
//       <div className="bg-black w-full h-[300px] rounded-b-3xl"></div>
//       <div className="overflow-x-auto -top-64 relative dark:bg-card-dark dark:text-text-dark bg-[#F7F9FF] p-5 rounded-3xl shadow-lg w-11/12 mx-auto min-h-[600px] -mt-10">
//         <div className="flex items-center align-middle">
//           <div
//             className="hover:bg-muted-dark cursor-pointer rounded-xl p-2"
//             onClick={() => window.history.back()}
//           >
//             {"< "}Back
//           </div>
//         </div>
//         <div className="text-2xl inter-font text-center my-5">
//           {fileMetadata?.fileName}
//         </div>
//         {blobInferredMimeType === "application/pdf" && (
//           <div className="zoomControls flex align-middle items-center gap-2 mb-5 justify-end me-5">
//             <label htmlFor="scale">Zoom: </label>
//             <input
//               type="range"
//               id="scale"
//               min="0.5"
//               max="2.0"
//               step="0.1"
//               value={scale}
//               onChange={(e) => setScale(parseFloat(e.target.value))}
//               className="accent-[#3dd598]"
//             />
//             <span>{scale.toFixed(1)}x</span>
//           </div>
//         )}
//         <div className="flex align-center justify-center">{renderViewer()}</div>
//       </div>
//     </div>
//   );
// };

// export default FileViewPage;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  downloadFile,
  getDriveFileById,
  getFileById,
} from "../../../services/fileServices/FileService";
import { useAuth } from "../../../firebase/AuthProvider";
import DriveFile from "../../../interfaces/file/DriveFile";
import PDFViewer from "./pdfViewer/PDFViewer";
import FileViewer from "react-file-viewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { formatFileSize } from "../../../utilities/fileUtils";

const FileViewPage: React.FC = () => {
  const { currentUser } = useAuth();
  const { fileId } = useParams();

  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [fileMetadata, setFileMetadata] = useState<DriveFile>();
  const [blobInferredMimeType, setBlobInferredMimeType] = useState<string>("");
  const [scale, setScale] = useState(1.0); // PDF Zoom
  const [zoom, setZoom] = useState<number>(1.0); // Image zoom
  const [textContent, setTextContent] = useState<string>(""); // For txt files

  // Collapsible side panel state
  const [isInfoPanelOpen, setIsInfoPanelOpen] = useState(true);

  useEffect(() => {
    const fetchFile = async () => {
      if (currentUser && fileId) {
        try {
          const authToken = await currentUser.getIdToken();
          const file: DriveFile | null = await getDriveFileById(
            fileId,
            authToken
          );

          if (file) {
            setFileMetadata(file);

            const url = await getFileById(fileId, authToken);
            if (url) {
              setFileUrl(url);

              // Fetch the blob to check its MIME type or extension
              const response = await fetch(url);
              const blob = await response.blob();

              // Infer the correct MIME type from file extension or fallback to blob.type
              let inferredMimeType = file.mimeType || blob.type;
              if (
                file.mimeType === null &&
                blob.type === "application/octet-stream"
              ) {
                const extension = file.fileName
                  ?.split(".")
                  .pop()
                  ?.toLowerCase();
                switch (extension) {
                  case "pdf":
                    inferredMimeType = "application/pdf";
                    break;
                  case "jpg":
                  case "jpeg":
                    inferredMimeType = "image/jpeg";
                    break;
                  case "png":
                    inferredMimeType = "image/png";
                    break;
                  case "txt":
                    inferredMimeType = "text/plain";
                    break;
                  case "doc":
                  case "docx":
                    inferredMimeType =
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                    break;
                  case "xls":
                  case "xlsx":
                    inferredMimeType =
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                    break;
                  case "mp4":
                  case "mov":
                  case "webm":
                  case "avi":
                    inferredMimeType = `video/${extension}`;
                    break;
                  // Add more if needed
                  default:
                    inferredMimeType = "application/octet-stream";
                }
              }

              setBlobInferredMimeType(inferredMimeType);

              // If it's a text file, read as text:
              if (inferredMimeType === "text/plain") {
                const text = await response.text();
                setTextContent(text);
              }
            }
          }
        } catch (error) {
          console.error("Error fetching file:", error);
        }
      }
    };

    fetchFile();
  }, [currentUser, fileId]);

  const renderViewer = () => {
    if (!fileUrl) return <div>Loading...</div>;

    // 1. Try to deduce file type from the MIME type or extension
    const extension = fileMetadata?.fileName?.split(".").pop()?.toLowerCase();

    // If we already inferred it's PDF by MIME or extension
    if (blobInferredMimeType === "application/pdf" || extension === "pdf") {
      return <PDFViewer fileUrl={fileUrl} scale={scale} />;
    }

    // If it's an image
    if (
      blobInferredMimeType.startsWith("image/") ||
      ["jpg", "jpeg", "png", "gif"].includes(extension || "")
    ) {
      return (
        <div className="file-viewer-container">
          <div
            className="image-container"
            style={{
              transform: `scale(${zoom})`,
              transformOrigin: "center",
              display: "inline-block",
            }}
          >
            <img
              src={fileUrl}
              alt={fileMetadata?.fileName}
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
          {/* Example Image Zoom Controls (if you want separate from PDF) */}
          <div className="flex items-center justify-end gap-2 mt-2">
            <label>Zoom: </label>
            <input
              type="range"
              min="0.5"
              max="3.0"
              step="0.1"
              value={zoom}
              onChange={(e) => setZoom(parseFloat(e.target.value))}
              className="accent-[#3dd598]"
            />
            <span>{zoom.toFixed(1)}x</span>
          </div>
        </div>
      );
    }

    // If it's text
    if (
      blobInferredMimeType === "text/plain" ||
      ["txt", "csv", "log"].includes(extension || "")
    ) {
      return (
        <div className="border p-4 rounded bg-white dark:bg-gray-800 max-w-full overflow-auto">
          <pre className="whitespace-pre-wrap break-words">{textContent}</pre>
        </div>
      );
    }

    // If it's video
    if (
      blobInferredMimeType.startsWith("video/") ||
      ["mp4", "webm", "mov", "avi"].includes(extension || "")
    ) {
      return (
        <video
          src={fileUrl}
          controls
          className="max-w-full h-auto bg-black"
          style={{ maxHeight: "70vh" }}
        />
      );
    }

    // Otherwise, let react-file-viewer handle it (e.g., doc, docx, xls, xlsx)
    const fileType = (() => {
      // If we haven't deduced, fallback to extension
      if (
        blobInferredMimeType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        return "docx";
      }
      if (
        blobInferredMimeType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        return "xlsx";
      }
      if (extension === "doc" || extension === "docx") return "docx";
      if (extension === "xls" || extension === "xlsx") return "xlsx";
      // Add more logic if needed
      return extension || "unknown";
    })();

    return (
      <FileViewer
        fileType={fileType}
        filePath={fileUrl}
        errorComponent={() => <div>Error loading file</div>}
        onError={(err) => console.log("FileViewer error:", err)}
      />
    );
  };

  const handleDownload = async (id: string, filename: string) => {
    try {
      if (currentUser) {
        const authToken: string = await currentUser.getIdToken();
        const blob = await downloadFile(id, authToken);
        if (blob) {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = filename; // Use the provided fileName
          document.body.appendChild(a);
          a.click();

          URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <div
      className="relative bg-[#F7F9FC] dark:bg-background-dark sm:min-w-[640px] 
    overflow-x-auto max-w-full h-screen overflow-hidden"
    >
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>
      <div
        className="-top-64 relative dark:bg-card-dark dark:text-text-dark 
                        bg-[#F7F9FF] rounded-3xl shadow-lg w-11/12 mx-auto 
                        min-h-[600px] -mt-10 overflow-hidden"
      >
        <div className="relative dark:bg-card-dark bg-[#F7F9FF] p-5 rounded-3xl mx-auto min-h-[600px]">
          <div className="flex items-center align-middle text-sm mb-5">
            <div
              className="cursor-pointer rounded-xl p-2 bg-white shadow-md hover:shadow-none"
              onClick={() => window.history.back()}
            >
              {"< "}Back
            </div>
          </div>

          {/* Title */}
          <div className="text-2xl inter-font text-center my-5">
            {fileMetadata?.fileName}
          </div>

          {/* PDF Zoom Controls */}
          {blobInferredMimeType === "application/pdf" && (
            <div className="zoomControls flex align-middle items-center gap-2 mb-5 justify-end me-5">
              <label htmlFor="scale">Zoom: </label>
              <input
                type="range"
                id="scale"
                min="0.5"
                max="2.0"
                step="0.1"
                value={scale}
                onChange={(e) => setScale(parseFloat(e.target.value))}
                className="accent-[#3dd598]"
              />
              <span>{scale.toFixed(1)}x</span>
            </div>
          )}

          {/* Main viewer + collapsible side panel */}
          <div className="flex gap-4">
            {/* File viewer area */}
            <div className="flex-grow flex items-center justify-center min-h-[400px] overflow-x-auto">
              {renderViewer()}
            </div>

            {/* Toggle Info Panel */}
            <button
              className="h-7 px-2 border bg-white -mr-5 z-50 rounded-full shadow-md self-start mt-2"
              onClick={() => setIsInfoPanelOpen(!isInfoPanelOpen)}
            >
              {isInfoPanelOpen ? (
                <FontAwesomeIcon icon={faCaretRight} />
              ) : (
                <FontAwesomeIcon icon={faCaretLeft} />
              )}
            </button>

            {/* Collapsible side panel */}
            {isInfoPanelOpen && (
              <div className="min-w-[200px] bg-white dark:bg-gray-800 rounded-xl p-4 shadow-md transition-all duration-300 overflow-auto">
                <h3 className="text-lg font-semibold mb-2 inter-font text-text">
                  File Info
                </h3>
                {fileMetadata && (
                  <ul className="text-sm space-y-2">
                    <li>
                      <strong>Name:</strong> {fileMetadata.fileName}
                    </li>
                    <li>
                      <strong>Type:</strong> {fileMetadata.mimeType}
                    </li>
                    <li>
                      <strong>Size:</strong> {formatFileSize(fileMetadata.size)}
                    </li>
                    <li>
                      <strong>Created:</strong>{" "}
                      {fileMetadata.createDate
                        ? new Date(fileMetadata.createDate).toLocaleString()
                        : "N/A"}
                    </li>
                    <div className="text-blue-500 cursor-pointer" onClick={() => handleDownload(fileMetadata.id, fileMetadata.fileName)}>Download</div>
                  </ul>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileViewPage;
