import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../firebase/AuthProvider";
import { useNavigate } from "react-router-dom";
import RecurringTaskDistributionStep from "./recurringTaskCreationSteps/RecurringTaskDistributionStep";
import TemplateTaskCreation from "./recurringTaskCreationSteps/TemplateTaskCreation";
import RecurringTaskRequiredActionsStep from "./recurringTaskCreationSteps/RecurringTaskRequiredActionsStep";
import RecurringTaskRequiredFilesStep from "./recurringTaskCreationSteps/RecurringTaskRequiredFilesStep";
import NewTaskAction from "../../../../interfaces/task/taskActions/NewTaskAction";
import TemplateFile from "../../../../interfaces/task/TaskFiles/TemplateFile";
import RecurringTaskDueDateStep from "./recurringTaskCreationSteps/RecurringTaskDueDateStep";
import RecurringTaskCreationDateStep from "./recurringTaskCreationSteps/RecurringTaskCreationDateStep";
import NewTemplateTask from "../../../../interfaces/templateTask/NewTemplateTask";
import { publishTemplateTask } from "../../../../services/templateService/TemplateTaskService";

interface OptionType {
  value: string;
  label: string;
}

const RecurringTaskCreation: React.FC = () => {
  const { currentUser, currentOrganization } = useAuth();
  const navigate = useNavigate();

  const [step, setStep] = useState<number>(1);
  const maxStepCount: number = 3;
  const [dueDateCalculationType, setDueDateCalculationType] =
    useState<string>();
  const [dueDateOffset, setDueDateOffset] = useState<string>();
  const [creationDateCalculationType, setCreationDateCalculationType] =
    useState<string>();
  const [creationDateOffset, setCreationDateOffset] = useState<string>();
  const [requiredActions, setRequiredActions] = useState<NewTaskAction[]>();
  const [requiredFiles, setRequiredFiles] = useState<TemplateFile[]>();
  const [taskId, setTaskId] = useState<number>();
  const [creationTemplateTaskErrorMessage, setCreationTemplateTaskErrorMessage] = useState("");

  const steps = Array.from({ length: maxStepCount }, (_, index) => ({
    id: index + 1,
  }));

  const SetDueDateCalculation = (calculationType: string, offset: string) => {
    setDueDateCalculationType(calculationType);
    setDueDateOffset(offset);
    setStep(step + 1);
  };

  const setCreationDateCalculation = (
    calculationType: string,
    offset: string
  ) => {
    setCreationDateCalculationType(calculationType);
    setCreationDateOffset(offset);
    setStep(step + 1);
  };

  const setRequiredActionsValue = (value: NewTaskAction[]) => {
    setRequiredActions(value);
    setStep(step + 1);
  };

  const setRequiredFilesValue = () => {
    setStep(step + 1);
  };

  const setTemplateTaskCreationValue = (taskId: number) => {
    setTaskId(taskId);
    setStep(step + 1);
    navigate('/edit/task/recurring')
  };

  useEffect(() => {
    if (step > maxStepCount) {
      navigate("/task/new/?success=true");
    }
  }, [steps]);

  const validEmployeeCalculationTypes = [
    "fixedDate", 
    "daysAfterCreation"    
  ]

  const creationTemplateTask = (newTemplateTask: NewTemplateTask) => {
    newTemplateTask.dueDateCalculationType = dueDateCalculationType
      ? dueDateCalculationType
      : "";
    newTemplateTask.dueDateOffset = dueDateOffset
      ? parseInt(dueDateOffset, 10).toString() || "0"
      : "0";
    newTemplateTask.creationDateCalculationType = creationDateCalculationType
      ? creationDateCalculationType
      : "";
    newTemplateTask.creationDateOffset = creationDateOffset
      ? parseInt(creationDateOffset, 10).toString() || "0"
      : "0";
    newTemplateTask.creationDateCron = creationDateOffset
      ? creationDateOffset
      : "";
    newTemplateTask.dueDateCron = dueDateOffset ? dueDateOffset : "";

    if ((newTemplateTask.assignmentType === "employees" || newTemplateTask.assignmentType === "employeeswithroles") && 
        (!validEmployeeCalculationTypes.includes(creationDateCalculationType || "")
        || !validEmployeeCalculationTypes.includes(dueDateCalculationType || "") )) {
          setCreationTemplateTaskErrorMessage("Employee assignment types only supports Fixed Dates and Days After Creation Date calculation types.");
          // console.log("Got here")
          return;
        }

    // Handle submitting the new task
    const createTask = async () => {
      if (currentUser !== null && newTemplateTask !== undefined) {
        const authToken: string = await currentUser.getIdToken();
        const newTaskId: number | null = await publishTemplateTask(
          newTemplateTask,
          authToken
        );
        return newTaskId;
      }
      return null;
    };
    const idPromise = createTask();

    idPromise.then((id) => {
      if (id !== null) {
        // Redirect to new task here.
        setTemplateTaskCreationValue(id);
      } else {
        console.error("Failed to create task");
      }
    });
  };

  return (
    <div className="bg-[#F7F9FC]">
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>
      <div className="w-11/12 p-5 mx-auto z-50 -top-64 relative  rounded-3xl bg-white">
      <div className="text-muted dark:text-muted-dark text-sm">Progress:</div>
      <div className="flex items-center gap-x-1 mb-5">
        {steps.map((localStep, index) => (
          <div
            key={localStep.id}
            className={`border dark:border-none w-full h-2.5 flex flex-col justify-center overflow-hidden text-xs text-white text-center whitespace-nowrap transition duration-500 ${
              step > index + 1
                ? "bg-primary"
                : step === index + 1
                ? "bg-primary-dark border-primary"
                : "bg-gray-300"
            }`}
            role="progressbar"
          ></div>
        ))}
        <div className="text-muted dark:text-muted-dark text-sm">
          {Math.ceil((step / maxStepCount) * 100)}%
        </div>
      </div>
      {step === 1 && (
        <RecurringTaskCreationDateStep
          setValue={setCreationDateCalculation}
          step={step}
        />
      )}
      {step === 2 && (
        <RecurringTaskDueDateStep
          setValue={SetDueDateCalculation}
          step={step}
        />
      )}
      {step === 3 && (
        // Grab distribution type, who the task is going to be assigned against
        // (e.g. for every participant, employee, SC, etc.)
        <TemplateTaskCreation setValue={creationTemplateTask} errorMessage={creationTemplateTaskErrorMessage} setErrorMessage={setCreationTemplateTaskErrorMessage}/>
      )}
      {/* {step === 4 && (
        // The next two steps are optional
        <RecurringTaskRequiredActionsStep
          setValue={setRequiredActionsValue}
          taskId={taskId}
          step={step}
        />
      )}
      {step === 5 && (
        <RecurringTaskRequiredFilesStep
          setValue={setRequiredFilesValue}
          taskId={taskId}
          step={step}
        />
      )} */}
      </div>
    </div>
  );
};

export default RecurringTaskCreation;
