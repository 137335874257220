import React, { useEffect, useState } from "react";
import Task from "../../../interfaces/task/Task";
import {
  calculateDateProximity,
  formatDateToMMDDYYYY,
} from "../../../utilities/dateUtils";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../firebase/AuthProvider";
import {
  fetchTasksWithCache,
} from "../../../services/taskServices/TaskService";
import TaskList from "../../../interfaces/task/TaskList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import "./Tasks.css";
import { getAvatarsFromStorage } from "../../../services/fileServices/FileService";
import Avatar from "../../../interfaces/avatar/Avatar";
import TaskUserList from "./TaskUserList";
import SmallTasksPageTable from "./SmallTaskTable";

type TaskSectionProps = {
  overdueTasksUnfiltered: TaskList[] | undefined;
  tasksDueTodayUnfiltered: TaskList[] | undefined;
  tasksDueThisWeekUnfiltered: TaskList[] | undefined;
  tasksDueThisMonthUnfiltered: TaskList[] | undefined;
  nonTemplateInterimsUnfiltered: TaskList[] | undefined;
  agingTasksUnfiltered: TaskList[] | undefined;
  allTasksUnfiltered: TaskList[] | undefined;
  userId?: string | null;
  toggleUserFilter: (id: string[]) => void;
  isLoading: boolean;
};

const TaskSection: React.FC<TaskSectionProps> = ({
  tasksDueTodayUnfiltered,
  tasksDueThisWeekUnfiltered,
  tasksDueThisMonthUnfiltered,
  overdueTasksUnfiltered,
  nonTemplateInterimsUnfiltered,
  allTasksUnfiltered,
  agingTasksUnfiltered,
  userId,
  toggleUserFilter,
  isLoading,
}) => {
  const maxTasksToShow = 10; // Maximum number of overdue tasks to display initially
  const { currentUser, currentRoles } = useAuth();

  const [activeList, setActiveList] = useState<string>("overdue");
  const [avatars, setAvatars] = useState<Avatar[] | null>();
  if (!userId) {
    userId = currentUser?.uid;
  }

  useEffect(() => {
    const storedAvatars = getAvatarsFromStorage();
    setAvatars(storedAvatars);
  }, []);

  var tasksDueToday = tasksDueTodayUnfiltered;
  var tasksDueThisWeek = tasksDueThisWeekUnfiltered;
  var tasksDueThisMonth = tasksDueThisMonthUnfiltered;
  var overdueTasks = overdueTasksUnfiltered;
  var nonTemplateInterims = nonTemplateInterimsUnfiltered;
  var allTasks = allTasksUnfiltered;

  allTasks = allTasksUnfiltered?.filter(
    (task) =>
      task.status.name !== "READY" && task.status.name !== "ISSUES FOUND"
  );

  if (
    currentRoles?.some((role) => role.name !== "SC") &&
    currentUser?.uid !== userId
  ) {
    tasksDueToday = tasksDueTodayUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );
    tasksDueThisWeek = tasksDueThisWeekUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );
    tasksDueThisMonth = tasksDueThisMonthUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );
    overdueTasks = overdueTasksUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );
    nonTemplateInterims = nonTemplateInterimsUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );
  } else if (currentRoles?.some((role) => role.name !== "SC")) {
  } else {
    tasksDueToday = tasksDueTodayUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );
    tasksDueThisWeek = tasksDueThisWeekUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );
    tasksDueThisMonth = tasksDueThisMonthUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );
    overdueTasks = overdueTasksUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );
    nonTemplateInterims = nonTemplateInterimsUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );
  }

  return (
    <div className="flex max-w-3/5 2xl: sm:w-full mx-auto dark:text-text-dark relative -top-44 -mb-44 mt-10 flex-grow h-auto min-h-[446px] flex-wrap overflow-wrap  justify-center gap-5">
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>
      <div className="bg-[#F7F9FF] w-11/12 mx-auto rounded-3xl relative -top-64 -mb-64 -mt-10 max-w-[1800px] min-h-[500px] border shadow-lg overflow-y-hidden">
        {isLoading && (
          <div className="absolute flex justify-center align-middle items-center w-full h-full">
            <div className="absolute  bg-gray-200 opacity-50 w-full h-full z-10"></div>
            <div className="bg-white h-[200px] w-[200px] flex justify-center items-center align-middle opacity-100 rounded-xl z-20 shadow-lg">
              <div className="flex text-[#3DD598] p-2 rounded items-center align-middle justify-center gap-2 hover:rotate-icon select-none text-lg">
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline w-7 h-7 text-gray-200 animate-spin dark:text-gray-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="#3DD598"
                  />
                </svg>
                Loading...
              </div>{" "}
            </div>
          </div>
        )}
        <h1 className="text-text inter-font text-2xl p-5">Dashboard</h1>
        <div className="dashboard-container flex h-full overflow-x-auto">
          <TaskUserList avatars={avatars} toggleUserFilter={toggleUserFilter} />
          <div className="task-collapsibles w-4/6">
            <div
              onClick={() => setActiveList("overdue")}
              className={`px-5 font-bold inter-font rounded-lg mx-2 mb-2 task-list overdue border text-lg ${
                activeList === "overdue" ? "bg-white" : "bg-gray-200"
              }`}
            >
              <div className="flex justify-between border-b">
                <div className="flex align-middle items-center gap-2">
                  Overdue{" "}
                  <div className="text-gray-500 text-base">
                    ({overdueTasks?.length ? overdueTasks.length : "0"})
                  </div>
                </div>{" "}
                <div>
                  <FontAwesomeIcon
                    icon={activeList === "overdue" ? faCaretDown : faCaretRight}
                  />
                </div>
              </div>
              <div className="text-sm">
                {activeList === "overdue" && overdueTasks && (
                  <div className=" bg-white -ps-10">
                    <SmallTasksPageTable
                      tasks={overdueTasks}
                      displayTaskCount={false}
                      avatars={avatars || []}
                    />
                  </div>
                )}
                {activeList === "overdue" && overdueTasks?.length === 0 && (
                  <div>There are currently no tasks to display...</div>
                )}
              </div>
            </div>
            <div
              onClick={() => setActiveList("due-today")}
              className={`px-5 font-bold inter-font rounded-lg mx-2 mb-2 task-list due-today border text-lg ${
                activeList === "due-today" ? "bg-white" : "bg-gray-200"
              }`}
            >
              <div className="flex justify-between border-b">
                <div className="flex align-middle items-center gap-2">
                  Due Today{" "}
                  <div className="text-gray-500 text-base">
                    ({tasksDueToday?.length ? tasksDueToday.length : "0"})
                  </div>
                </div>{" "}
                <div>
                  <FontAwesomeIcon
                    icon={
                      activeList === "due-today" ? faCaretDown : faCaretRight
                    }
                  />
                </div>
              </div>
              <div className="text-sm">
                {activeList === "due-today" &&
                  tasksDueToday &&
                  tasksDueToday?.length > 0 && (
                    <div className=" bg-white -ps-10">
                      <SmallTasksPageTable
                        tasks={tasksDueToday}
                        displayTaskCount={false}
                        avatars={avatars || []}
                      />
                    </div>
                  )}
                {activeList === "due-today" && tasksDueToday?.length === 0 && (
                  <div className="bg-white mt-5">
                    There are currently no tasks to display...
                  </div>
                )}
              </div>
            </div>
            <div
              onClick={() => setActiveList("due-this-week")}
              className={`px-5 font-bold inter-font rounded-lg mx-2 mb-2 task-list due-this-week border text-lg ${
                activeList === "due-this-week" ? "bg-white" : "bg-gray-200"
              }`}
            >
              <div className="flex justify-between border-b">
                <div className="flex align-middle items-center gap-2">
                  Due This Week{" "}
                  <div className="text-gray-500 text-base">
                    ({tasksDueThisWeek?.length ? tasksDueThisWeek.length : "0"})
                  </div>
                </div>{" "}
                <div>
                  <FontAwesomeIcon
                    icon={
                      activeList === "due-this-week"
                        ? faCaretDown
                        : faCaretRight
                    }
                  />
                </div>
              </div>
              <div className="text-sm">
                {activeList === "due-this-week" &&
                  tasksDueThisWeek &&
                  tasksDueThisWeek.length > 0 && (
                    <div className=" bg-white -ps-10">
                      <SmallTasksPageTable
                        tasks={tasksDueThisWeek}
                        displayTaskCount={false}
                        avatars={avatars || []}
                      />
                    </div>
                  )}
                {activeList === "due-this-week" &&
                  tasksDueThisWeek?.length === 0 && (
                    <div className="mt-5">
                      There are currently no tasks to display...
                    </div>
                  )}
              </div>
            </div>
            <div
              onClick={() => setActiveList("due-this-month")}
              className={`px-5 font-bold inter-font rounded-lg mx-2 mb-2 task-list due-this-month border text-lg ${
                activeList === "due-this-month" ? "bg-white" : "bg-gray-200"
              }`}
            >
              <div className="flex justify-between border-b">
                <div className="flex align-middle items-center gap-2">
                  Due This Month{" "}
                  <div className="text-gray-500 text-base">
                    (
                    {tasksDueThisMonth?.length ? tasksDueThisMonth.length : "0"}
                    )
                  </div>
                </div>{" "}
                <div>
                  <FontAwesomeIcon
                    icon={
                      activeList === "due-this-month"
                        ? faCaretDown
                        : faCaretRight
                    }
                  />
                </div>
              </div>
              <div className="text-sm">
                {activeList === "due-this-month" && tasksDueThisMonth && (
                  <div className=" bg-white -ps-10">
                    <SmallTasksPageTable
                      tasks={tasksDueThisMonth}
                      displayTaskCount={false}
                      avatars={avatars || []}
                    />
                  </div>
                )}
                {activeList === "due-this-month" &&
                  tasksDueThisMonth?.length === 0 && (
                    <div className="mt-5">
                      There are currently no tasks to display...
                    </div>
                  )}
              </div>
            </div>
            <div
              onClick={() => setActiveList("interim")}
              className={`px-5 font-bold inter-font rounded-lg mx-2 mb-2 task-list interim border text-lg ${
                activeList === "interim" ? "bg-white" : "bg-gray-200"
              }`}
            >
              <div className="flex justify-between border-b">
                <div className="flex align-middle items-center gap-2">
                  Interim{" "}
                  <div className="text-gray-500 text-base">
                    (
                    {nonTemplateInterims?.length
                      ? nonTemplateInterims.length
                      : "0"}
                    )
                  </div>
                </div>{" "}
                <div>
                  <FontAwesomeIcon
                    icon={activeList === "interim" ? faCaretDown : faCaretRight}
                  />
                </div>
              </div>
              <div className="text-sm">
                {activeList === "interim" && nonTemplateInterims && (
                  <div className=" bg-white -ps-10">
                    <SmallTasksPageTable
                      tasks={nonTemplateInterims}
                      displayTaskCount={false}
                      avatars={avatars || []}
                    />
                  </div>
                )}
                {activeList === "interim" &&
                  nonTemplateInterims?.length === 0 && (
                    <div className="mt-5">
                      There are currently no tasks to display...
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className="aging-report-section bg-white rounded-lg mx-5 mb-10 border w-2/6 flex flex-col justify-stretch">
            <div className="review-section p-2 min-h-[150px]">
              <h2 className="inter-font text-text text-lg text-center">
                Awaiting Review
              </h2>
              <div className="review-content-section">
                {/* Let's talk about the conditions here.
                  If this is an SC, we are going to want to get all assignments that aren't assigned to them, right? 
                */}
                <ReviewTable tasks={allTasks} avatars={avatars} />
              </div>
            </div>
            <div className="aging-report-content p-2">
              <h2 className="inter-font text-text text-lg text-center">
                Aging Report
              </h2>
              <div className="review-content-section">
                {/* Let's talk about the conditions here.
                  If this is an SC, we are going to want to get all assignments that aren't assigned to them, right? 
                */}
                <ReviewTable tasks={agingTasksUnfiltered} avatars={avatars} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type ReviewTableProps = {
  tasks: TaskList[] | undefined;
  avatars: Avatar[] | undefined | null;
};

const ReviewTable: React.FC<ReviewTableProps> = ({ tasks, avatars }) => {
  const findAvatarById = (userId: string): string | null => {
    const avatarObj = avatars?.find((avatar) => avatar.id === userId);
    return avatarObj ? `data:image/png;base64,${avatarObj.avatar}` : null;
  };

  const navigate = useNavigate();

  return (
    <div className="review-table-container text-[12px]">
      <div className="headers">
        <div className="col-header">Type</div>
        <div className="col-header">Participant</div>
        <div className="col-header">Waiver</div>
        <div className="col-header">Assignee</div>
        <div className="col-header">Due Date</div>
      </div>
      <div className="data-section max-h-[300px] overflow-y-auto">
      {tasks?.map((task, index) => (
        <div
          key={index}
          className="table-data"
          onClick={() => navigate(`/task/${task.organization.name}-${task.id}`)}
        >
          <div className="col-data">
            {task.subType.type
              ? task.subType.type
                  .toLowerCase()
                  .split(" ")
                  .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                  .join(" ")
              : "N/A"}
          </div>
          <div className="col-data">
            {task.participant
              ? task.participant?.firstName + " " + task.participant?.lastName
              : "N/A"}
          </div>
          <div className="col-data">
            {task.participant?.waiver?.waiverName
              ? task.participant?.waiver?.waiverName
              : "N/A"}
          </div>
          <div className="col-data flex">
            {task.assignees
              .filter((a) => !a.isReporter) // Exclude reporters
              .map((user, index) => {
                const avatarUrl = findAvatarById(user.user.id);
                return (
                  <div
                    className="w-3 h-3 font-bold flex items-center justify-center rounded-full bg-gray-300 dark:text-text"
                    key={index}
                  >
                    {avatarUrl ? (
                      <img
                        src={avatarUrl}
                        alt={`${user.user.firstName} ${user.user.lastName}`}
                        className="w-full h-full rounded-full object-cover"
                      />
                    ) : (
                      <div className="text-[8px]">
                        {user.user.firstName[0] + user.user.lastName[0]}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
          <div
            className={`col-data ${
              calculateDateProximity(task.endDate) === "Overdue"
                ? "text-red-500"
                : ""
            }`}
          >
            {formatDateToMMDDYYYY(task.endDate)}
          </div>
        </div>
      ))}
      </div>
      {tasks?.length === 0 && (
        <div className="text-muted text-center p-2 inter-font">
          No tasks found...
        </div>
      )}
    </div>
  );
};

type TaskTableProps = {
  overdueTasks: TaskList[] | undefined;
  tasksDueToday: TaskList[] | undefined;
  tasksDueThisWeek: TaskList[] | undefined;
  tasksDueThisMonth: TaskList[] | undefined;
  nonTemplateInterims: TaskList[] | undefined;
  agingTasks: TaskList[] | undefined;
  allTasks: TaskList[] | undefined;
  userId?: string | null;
  toggleUserFilter: (id: string[]) => void;
  isLoading: boolean;
};

const TaskTable: React.FC<TaskTableProps> = ({
  overdueTasks,
  tasksDueToday,
  tasksDueThisWeek,
  tasksDueThisMonth,
  nonTemplateInterims,
  agingTasks,
  allTasks,
  userId,
  toggleUserFilter,
  isLoading,
}) => {
  return (
    <div className="flex flex-col w-full flex-wrap flex-grow h-auto">
      <TaskSection
        overdueTasksUnfiltered={overdueTasks}
        tasksDueThisMonthUnfiltered={tasksDueThisMonth}
        tasksDueThisWeekUnfiltered={tasksDueThisWeek}
        tasksDueTodayUnfiltered={tasksDueToday}
        nonTemplateInterimsUnfiltered={nonTemplateInterims}
        agingTasksUnfiltered={agingTasks}
        allTasksUnfiltered={allTasks}
        userId={userId}
        toggleUserFilter={toggleUserFilter}
        isLoading={isLoading}
      />
    </div>
  );
};

interface TaskProps {
  userId?: string;
}
const Tasks: React.FC<TaskProps> = ({ userId = null }) => {
  const [tasks, setTasks] = useState<TaskList[]>([]);
  const [allTasks, setAllTasks] = useState<TaskList[]>([]);
  const [userFilter, setUserFilter] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { currentUser } = useAuth();

  // Our main effect that fires when the currentUser or userFilter changes
  useEffect(() => {
    if (!currentUser) return;

    const fetchTasks = async () => {
      const authToken = await currentUser.getIdToken();

      // 1. If no one is selected in userFilter, do "default" behavior:
      if (userFilter.length === 0) {
        const tasksForSingleUser = await getDefaultTasks(authToken);
        updateTaskStates(tasksForSingleUser);
        return;
      }

      // 2. If multiple users are selected, fetch tasks for each user.
      const allSelectedUserTasks: TaskList[] = [];

      for (const filteredUserId of userFilter) {
        setIsLoading(true);
        const tasksForThisUser = await fetchTasksWithCache(
          authToken,
          filteredUserId,
          false
        );
        if (tasksForThisUser) {
          // Filter out completed tasks right away if you wish
          const incompleteTasks = tasksForThisUser.filter(
            (t) => t.status?.name !== "COMPLETED"
          );
          allSelectedUserTasks.push(...incompleteTasks);
        }
        setIsLoading(false);
      }

      // Sort them by endDate if you want
      const sortedCombined = allSelectedUserTasks.sort(
        (a, b) => new Date(a.endDate).getTime() - new Date(b.endDate).getTime()
      );

      setAllTasks(sortedCombined);
      setTasks(sortedCombined);
    };

    // This function is the default logic.
    const getDefaultTasks = async (authToken: string) => {
      const targetUserId = userId || currentUser.uid;
      const fetchedTasks = await fetchTasksWithCache(authToken, targetUserId);
      return fetchedTasks?.filter((t) => t.status.name !== "COMPLETED") || [];
    };

    const updateTaskStates = (fetchedTasks: TaskList[]) => {
      // Sort tasks
      const sortedTasks = fetchedTasks.sort(
        (a, b) => new Date(a.endDate).getTime() - new Date(b.endDate).getTime()
      );
      // Update state
      setAllTasks(sortedTasks);
      if (tasks?.length === 0 && userFilter.length === 0) {
        setTasks(sortedTasks);
      } else {
        // If, for some reason, you want to auto-filter further,
        // do it here. Otherwise, set them directly.
        setTasks(sortedTasks);
      }
    };

    fetchTasks();
  }, [currentUser, userFilter, userId]);

  // Toggling user filter
  const toggleUserFilter = (ids: string[]) => {
    setUserFilter(ids);
  };

  // Time-based filtering logic, unchanged:
  const today = new Date();
  const endOfToday = new Date(today);
  endOfToday.setHours(23, 59, 59, 999);

  const endOfThisWeek = new Date(today);
  endOfThisWeek.setDate(endOfThisWeek.getDate() + (7 - endOfThisWeek.getDay()));

  const endOfNextMonth = new Date(today);
  endOfNextMonth.setMonth(endOfNextMonth.getMonth() + 1);
  endOfNextMonth.setHours(23, 59, 59, 999);

  const agingTasks = tasks.filter(
    (task) =>
      task.participant &&
      !task.participant.cpocDate
  );

  const tasksDueToday = tasks.filter(
    (task) =>
      new Date(task.endDate) >= today &&
      new Date(task.endDate) <= endOfToday &&
      task.fromTemplate &&
      task.participant &&
      task.participant.cpocDate
  );

  const tasksDueThisWeek = tasks.filter(
    (task) =>
      new Date(task.endDate) > endOfToday &&
      new Date(task.endDate) <= endOfThisWeek &&
      task.fromTemplate &&
      task.participant &&
      task.participant.cpocDate
  );

  const tasksDueThisMonth = tasks.filter(
    (task) =>
      new Date(task.endDate) > endOfThisWeek &&
      new Date(task.endDate) <= endOfNextMonth &&
      task.fromTemplate &&
      task.participant &&
      task.participant.cpocDate
  );

  const overdueTasks = tasks.filter(
    (task) =>
      new Date(task.endDate) < today &&
      task.fromTemplate &&
      task.participant &&
      task.participant.cpocDate
  );

  const nonTemplateInterims = tasks.filter(
    (task) =>
      !task.fromTemplate &&
      task.participant &&
      task.participant.cpocDate
  );

  return (
    <div className="flex flex-col flex-wrap flex-grow h-auto">
      <TaskTable
        overdueTasks={overdueTasks}
        tasksDueThisMonth={tasksDueThisMonth}
        tasksDueThisWeek={tasksDueThisWeek}
        tasksDueToday={tasksDueToday}
        nonTemplateInterims={nonTemplateInterims}
        agingTasks={agingTasks}
        allTasks={tasks}
        userId={userId}
        toggleUserFilter={toggleUserFilter}
        isLoading={isLoading}
      />
    </div>
  );
};

export default Tasks;
