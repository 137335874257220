import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../firebase/AuthProvider";
import {
  approveTask,
  getTaskWorkflow,
  rejectTask,
} from "../../../../services/taskServices/TaskWorkflowServices";
import TaskWorkflow from "../../../../interfaces/task/workflow/TaskWorkflow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faBan,
  faCircleCheck,
  faCircleXmark,
  faClockRotateLeft,
  faForwardStep,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import Select, { SingleValue } from "react-select";
import { User } from "../../../../interfaces/User";
import TaskApproval from "../../../../interfaces/task/workflow/TaskApproval";
import { formatDateToWords } from "../../../../utilities/dateUtils";

interface TaskWorkflowDiagramProps {
  taskId: string;
  isReady: boolean;
  status: string;
  reload: boolean;
  setReload: (anything: boolean) => void;
}
interface OptionType {
  value: string;
  label: string;
}

const TaskWorkflowDiagram: React.FC<TaskWorkflowDiagramProps> = ({
  taskId,
  isReady,
  status,
  reload,
  setReload,
}) => {
  const { currentUser, currentRoles } = useAuth();
  const [workflowItems, setWorkflowItems] = useState<TaskWorkflow[]>();
  const [rejectStepPrompt, setRejectStepPrompt] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [assignmentOptions, setAssignmentOptions] = useState<OptionType[]>([]); // Adjusted type for ReactSelect
  const [assignee, setAssignee] = useState<TaskApproval | undefined>(); // Adjusted type for ReactSelect
  const [durationInMinutes, setDurationInMinutes] = useState<number>(0);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const fetchWorkflows = async () => {
      if (currentUser !== null && taskId !== null) {
        const authToken: string = await currentUser.getIdToken();
        const fetchedWorkflows = await getTaskWorkflow(taskId, authToken);
        return fetchedWorkflows;
      }
    };

    if (workflowItems !== null) {
      const idPromise = fetchWorkflows();

      idPromise.then((items) => {
        if (items !== null) {
          // Redirect to new task here.
          items?.sort((a, b) => a.stepOrder - b.stepOrder);
          setWorkflowItems(items);
        } else {
          console.error("Failed to create comment");
        }
      });
    }
  }, [currentUser, taskId]);

  const handleNextStep = async () => {
    setIsLoading(true);
    if (currentUser !== null) {
      const authToken = await currentUser.getIdToken();
      approveTask(taskId, authToken)
        .then((res) => {
          setReload(!reload);
        })
        .catch((err) => {
          console.log("Error:", err);
          setReload(!reload);
        })
        .finally(() => {});
    }
    setIsLoading(false);
  };

  const toggleRejectStepPrompt = () => {
    setRejectStepPrompt(!rejectStepPrompt);
  };

  const validateRejectFrom = () => {
    if (assignee === null || assignee === undefined) {
      setError("You must select a user to assign to on rejection.");
      return false;
    }
    return true;
  };

  const handleRejectStep = async () => {
    if (currentUser !== null && validateRejectFrom() && assignee) {
      const authToken = await currentUser.getIdToken();
      rejectTask(taskId, feedback, assignee?.id, durationInMinutes, authToken)
        .then((res) => {
          setReload(!reload);
        })
        .catch((err) => {
          console.log("Error:", err);
          setReload(!reload);
        })
        .finally(() => {});
    }
  };

  const handleFeedbackChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    setFeedback(e.target.value);
  };

  const handleDurationInMinutesChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 0) {
      setDurationInMinutes(value);
    }
  };

  const handleAssignmentChange = (selectedOption: SingleValue<OptionType>) => {
    const assignee = selectedOption ? selectedOption.value : "";
    const newAssignee = workflowItems?.find(
      (wi) => wi.taskApproval?.id.toString() === assignee
    )?.taskApproval;
    if (newAssignee) {
      setAssignee(newAssignee);
    }
  };

  const filteredList = workflowItems?.filter(
    (obj) => obj.taskApproval?.approvalDate === undefined
  );
  const maxStepOrderObject =
    filteredList !== undefined && filteredList?.length > 0
      ? filteredList?.reduce((prev, current) =>
          prev.stepOrder < current.stepOrder ? prev : current
        )
      : undefined;

  const roleName = maxStepOrderObject?.role.name;
  const isInCurrentUserRoles = currentRoles?.some(
    (role) => role.name === roleName
  );
  const hasNullApprovalDate = workflowItems?.some((obj) => {
    const conditionMet =
      obj.stepOrder === 1 &&
      (!obj.taskApproval || obj.taskApproval.approvalDate === undefined);

    return conditionMet;
  });

  useEffect(() => {
    if (workflowItems !== undefined) {
      const maxId = workflowItems.reduce((max, obj) => {
        // Check if TaskApproval is defined
        if (obj.taskApproval !== undefined && obj.stepOrder > max) {
          return obj.stepOrder;
        }
        return max;
      }, 0);

      const maxIdMinusOne = workflowItems.find(
        (obj) => obj.stepOrder === maxId - 1
      )?.taskApproval;

      const previousApprovers = workflowItems.filter(
        (obj) => obj.stepOrder < maxId
      );

      const options = previousApprovers.map((item) => ({
        value: item?.taskApproval?.id?.toString() || "", // Ensure value is always a string
        label:
          item.taskApproval?.approver?.firstName &&
          item.taskApproval?.approver?.lastName
            ? `${item.taskApproval.approver.firstName} ${item.taskApproval.approver.lastName} (${item.role.name})`
            : "",
      }));

      if (maxIdMinusOne && options) {
        setAssignee(maxIdMinusOne);
        setAssignmentOptions(options);
      }
    }
  }, [workflowItems]);

  return (
    <div className="mb-5 w-full">
      <div className="flex justify-between mx-3 text-sm w-full">
        <div className="w-full"></div>
        {workflowItems &&
          workflowItems.map((item, index) => (
            <div
              key={index}
              className="flex justify-between align-middle group relative"
            >
              <div className="relative border w-16 h-16 dark:text-text-dark shadow-lg rounded-full p-2 flex justify-center items-center align-middle">
                <div>
                  <div className="text-xl text-center">
                    {item.role.name === "SC" && "Sc"}
                    {item.role.name === "SUPERVISOR" && "Sup"}
                    {item.role.name === "PROGRAM MANAGER" && "PM"}
                    {item.role.name === "OWNER" && "BOSS"}
                  </div>
                  {item.taskApproval?.approvalDate && (
                    <div className="rounded-full p-2 bg-green-600 w-5 h-5 flex justify-center items-center align-middle absolute bottom-[-10px] right-[21px]">
                      <div className="text-green-100 flex justify-center items-center align-middle text-center">
                        <FontAwesomeIcon icon={faCircleCheck} />
                      </div>
                    </div>
                  )}
                  {item.taskApproval &&
                    !item.taskApproval.approvalDate &&
                    !item.taskApproval.rejected && (
                      <div className="rounded-full p-2 bg-yellow-600 w-5 h-5 flex justify-center items-center align-middle absolute bottom-[-10px] right-[21px]">
                        <div className="text-yellow-100 flex justify-center items-center align-middle text-center">
                          <FontAwesomeIcon icon={faClockRotateLeft} />
                        </div>
                      </div>
                    )}
                  {!item.taskApproval && (
                    <div className="rounded-full p-2 bg-gray-300 w-5 h-5 flex justify-center items-center align-middle absolute bottom-[-10px] right-[21px]">
                      <div className="text-gray-100 flex justify-center items-center align-middle text-center">
                        <FontAwesomeIcon icon={faBan} />
                      </div>
                    </div>
                  )}
                  {item.taskApproval?.rejected && (
                    <div className="rounded-full bg-red-600 w-5 h-5 flex justify-center items-center align-middle absolute bottom-[-10px] right-[21px]">
                      <div className="text-red-100">
                        <FontAwesomeIcon icon={faCircleXmark} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {item.stepOrder < workflowItems.length && (
                <div className="my-auto mx-2 text-xl">
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              )}
              <div className="absolute text-xs min-w-[200px] top-20 bg-gray-800 text-white px-2 py-1 rounded-md opacity-0 group-hover:opacity-80 transition-opacity duration-300">
                <div>
                  {item.taskApproval?.approvalDate  &&
                    <div>
                      <div>Approved by {item.approver?.firstName + " " + item.approver?.lastName} </div>
                      <div className="text-[10px] text-muted-dark">{formatDateToWords(item.taskApproval.approvalDate)}</div>
                    </div>
                  }
                  {
                    item.taskApproval &&
                    !item.taskApproval.approvalDate &&
                    !item.taskApproval.rejected && (
                      <div className="text-center">Assigned to {item.approver?.firstName + " " + item.approver?.lastName}</div>
                    )
                  }
                  {item.taskApproval?.rejected && (
                    <div>Rejected by {item.approver?.firstName + " " + item.approver?.lastName}</div>
                  )}
                  {!item.taskApproval && (
                    <div className="text-center text-muted-dark">Approval not yet assigned</div>
                  )}
                </div>
              </div>
            </div>
          ))}
        {isInCurrentUserRoles ? (
          <div className="flex justify-end w-full text-end align-center items-center pe-5 gap-2">
            {!hasNullApprovalDate && (
              <div>
                <button
                  onClick={toggleRejectStepPrompt}
                  className="bg-danger text-text-dark rounded p-2 h-7 w-7 items-center align-center text-center flex justify-center"
                >
                  <FontAwesomeIcon icon={faX} />
                </button>
                {rejectStepPrompt && (
                  <div className="absolute border p-5 rounded-3xl border-opacity-100 bg-white dark:bg-gray-800 remove-input-txt-border">
                    <div className="text-start">Assign to</div>
                    <Select
                      id="assignment"
                      name="assignment"
                      value={assignmentOptions.find(
                        (option) => option.value === assignee?.id.toString()
                      )}
                      onChange={handleAssignmentChange}
                      options={assignmentOptions}
                      className="your-custom-class text-start"
                      placeholder={"Choose who to assign to..."}
                      classNamePrefix="select"
                    />
                    <div className="text-start mt-3">
                      How long did it take you to review? (in minutes)
                    </div>
                    <input
                      id="durationInMinutes"
                      name="durationInMinutes"
                      placeholder="0"
                      type="number"
                      min="0"
                      value={durationInMinutes}
                      onChange={handleDurationInMinutesChange}
                      required
                      className="shadow resize-none appearance-none border rounded w-full py-2 px-3 text-text dark:text-text leading-tight focus:outline-none focus:shadow-outline"
                    />{" "}
                    <div className="text-start mt-3">Feedback</div>
                    <textarea
                      id="feedback"
                      name="feedback"
                      placeholder="Feedback..."
                      value={feedback}
                      onChange={handleFeedbackChange}
                      required
                      className="shadow resize-none appearance-none border rounded w-full py-2 px-3 text-text dark:text-text leading-tight focus:outline-none focus:shadow-outline"
                    />{" "}
                    <div className="flex justify-end mt-2">
                      <button
                        onClick={handleRejectStep}
                        className="bg-danger w-full rounded-xl text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline"
                      >
                        Reject Task
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="flex justify-end">
              {status !== "COMPLETED" && (
                <div>
                  <div>
                    {isReady && !isLoading && (
                      <button
                        className="bg-[#3DD598] text-white rounded p-1 flex justify-center gap-2 align-middle"
                        onClick={handleNextStep}
                      >
                        <div>Next </div>
                        <div>
                          <FontAwesomeIcon
                            className="text-sm"
                            icon={faForwardStep}
                          />
                        </div>
                      </button>
                    )}
                    {isReady && isLoading && (
                      <button
                        disabled
                        type="button"
                        className="py-1 px-1 text-sm font-medium w-full text-center text-white bg-[#3DD598] rounded border border-gray-200 hover:bg-[#3DD598] hover:text-white focus:z-10 focus:ring-2 focus:ring-white focus:text-white dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex justify-center items-center align-middle"
                      >
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="inline w-4 h-4 me-1 text-gray-200 animate-spin dark:text-gray-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="#3DD598"
                          />
                        </svg>
                        Loading...
                      </button>
                    )}
                    {!isReady && (
                      <button
                        disabled
                        className="bg-gray-300 text-white rounded p-1 flex justify-center gap-2 align-middle"
                      >
                        <div>Next </div>
                        <div>
                          <FontAwesomeIcon
                            className="text-sm"
                            icon={faForwardStep}
                          />
                        </div>
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="flex justify-end w-full text-end align-center items-center pe-5 gap-2"></div>
        )}
      </div>
    </div>
  );
};

export default TaskWorkflowDiagram;
