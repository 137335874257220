import React, { useEffect, useState } from "react";
import TaskList from "../../../interfaces/task/TaskList";
import { useNavigate } from "react-router-dom";
import {
  calculateDateProximity,
  formatDateToMMDDYYYY,
} from "../../../utilities/dateUtils";
import Avatar from "../../../interfaces/avatar/Avatar";

interface SmallTasksPageTable {
  tasks: TaskList[];
  avatars: Avatar[];
  displayTaskCount: boolean;
}

const SmallTasksPageTable: React.FC<SmallTasksPageTable> = ({
  tasks,
  avatars,
  displayTaskCount = false,
}) => {
  const navigate = useNavigate();
  const [displayCount, setDisplayCount] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  const findAvatarById = (userId: string): string | null => {
    const avatarObj = avatars?.find((avatar) => avatar.id === userId);
    return avatarObj ? `data:image/png;base64,${avatarObj.avatar}` : null;
  };

  const loadMore = () => {
    setPage((currentPage) => currentPage + 1);
  };

  useEffect(() => { 

  }, [tasks])

  return (
    <div className="mt-2">
      <div className="text-muted mx-auto text-end pe-8 pb-3 pt-0 text-[10px]">
        {tasks && displayTaskCount ? tasks.length + " tasks" : ""}
      </div>
      <div className="dark:bg-card-dark min-w-full overflow-x-auto shadow-lg mb-5 dark:text-text-dark rounded-lg inter-font text-[11px] max-w-[600px]">
        <div className="font-bold headers columns-8 px-5 py-0.5 border-b bg-gray-200">
          <div className="truncate max-w-[100px] min-w-[80px]">Title</div>
          <div className="truncate max-w-[100px] min-w-[80px]">Attachment</div>
          <div className="truncate max-w-[100px] min-w-[80px]">Participant</div>
          <div className="truncate max-w-[100px] min-w-[80px]">Type</div>
          <div className="truncate max-w-[100px] min-w-[80px]">Assignee</div>
          <div className="truncate max-w-[100px] min-w-[80px]">Due Date</div>
          <div className="truncate max-w-[100px] min-w-[80px]">Status</div>
        </div>
        {tasks?.slice(0, page * displayCount).map((task, index) => (
          <div
            onClick={() => navigate(`/task/BLOSSOM-${task.id}`)}
            key={index}
            className={`data columns-8 truncate py-0.5 px-5 hover:shadow-lg cursor-pointer hover:bg-background dark:hover:bg-background-dark border-t border-b ${
              index % 2 === 1 ? "bg-gray-100 dark:bg-gray-800" : ""
            }`}
          >
            <div className="truncate max-w-[100px] min-w-[80px]">
              {task.title}
            </div>
            <div className="truncate max-w-[100px] min-w-[80px] ms-8">
              <input
                type="checkbox"
                disabled={true}
                checked={task.hasAttachments}
                className="checked:bg-[#3DD598] checked:disabled:hover:bg-[#3DD598] bg-gray-400 border-none rounded "
              />
            </div>
            <div className="truncate max-w-[100px] min-w-[80px] ">
              {task.participant?.firstName
                ? task.participant.firstName[0] +
                  ". " +
                  task.participant.lastName
                : "N/A"}
            </div>
            <div className="truncate max-w-[100px] min-w-[80px] ">
              {task.subType.type
                ? task.subType.type
                    .toLowerCase()
                    .split(" ")
                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(" ")
                : "N/A"}
            </div>
            <div className="truncate max-w-[100px] min-w-[80px] flex flex-nowrap overflow-hidden ">
              {task.assignees
                .filter((a) => !a.isReporter) // Exclude reporters
                .map((user, index) => {
                  const avatarUrl = findAvatarById(user.user.id);
                  return (
                    <div
                      className="w-7 h-7 font-bold flex items-center justify-center rounded-full bg-gray-300 dark:text-text"
                      key={index}
                    >
                      {avatarUrl ? (
                        <img
                          src={avatarUrl}
                          alt={`${user.user.firstName} ${user.user.lastName}`}
                          className="w-full h-full rounded-full object-cover"
                        />
                      ) : (
                        <div className="text-[12px]">
                          {user.user.firstName[0] + user.user.lastName[0]}
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
            <div
              className={`truncate max-w-[100px] min-w-[80px] ${
                calculateDateProximity(task.endDate) === "Overdue"
                  ? "text-red-500"
                  : ""
              }`}
            >
              {formatDateToMMDDYYYY(task.endDate)}
            </div>
            <div className="truncate max-w-[100px] min-w-[80px] flex gap-1">
              <div>
                {task.status.name
                  .toLowerCase()
                  .split(" ")
                  .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                  .join(" ")}
              </div>
              {task.assigneeRoleName !== undefined &&
                task.status.name === "IN REVIEW" && (
                  <div className="font-bold">
                    {task.assigneeRoleName === "SC" && "(SC)"}
                    {task.assigneeRoleName === "SUPERVISOR" && "(SUP)"}
                    {task.assigneeRoleName === "PROGRAM MANAGER" && "(PM)"}
                  </div>
                )}
            </div>
          </div>
        ))}
      </div>
      {page * displayCount < tasks.length && (
        <div className="flex justify-center align-middle items-center">
          <div className="hover:text-blue-200 rounded-lg p-2 text-blue-500 font-light cursor-pointer" onClick={loadMore}>
            Load more
          </div>
        </div>
      )}
    </div>
  );
};

export default SmallTasksPageTable;
