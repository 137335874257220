import React, { useEffect, useState } from "react";
import TextEditor from "../../textEditor/TextEditor";
import InterimTemplateResponse from "../../../../interfaces/task/InterimTemplateResponse";
import { useAuth } from "../../../../firebase/AuthProvider";
import { useNavigate } from "react-router-dom";
import NewInterimTemplate from "../../../../interfaces/task/NewInterimTemplate";
import { updateInterimTemplate } from "../../../../services/taskServices/TaskService";
import Select, { ActionMeta, MultiValue, SingleValue } from "react-select";
import { getTemplateFiles } from "../../../../services/fileServices/FileService";
import TemplateFile from "../../../../interfaces/task/TaskFiles/TemplateFile";
import { getAllTags } from "../../../../services/tagServices/TagService"; // <-- your tag service
import Tag from "../../../../interfaces/tag/Tag"; // <-- your Tag interface

interface EditTemplateInterimTaskProps {
  template: InterimTemplateResponse;
  setSelectedTemplate: (value: InterimTemplateResponse | undefined) => void;
}
interface OptionType {
  value: string | number;
  label: string;
}

const EditTemplateInterimTask: React.FC<EditTemplateInterimTaskProps> = ({
  template,
  setSelectedTemplate,
}) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // State for the "required files"
  const [formattedFileTemplates, setFormattedFileTemplates] = useState<OptionType[]>([]);
  const [fileTemplates, setFileTemplates] = useState<TemplateFile[] | null>(null);

  // State for tags
  const [allTags, setAllTags] = useState<Tag[]>([]);
  const [formattedTags, setFormattedTags] = useState<OptionType[]>([]);

  const [newTemplate, setNewTemplate] = useState<NewInterimTemplate>({
    id: template.id,
    title: template.title,
    description: template.description,
    information: template.information,
    requiredFiles: template.requiredFiles.map((file) => file.id.toString()),
    assignee: undefined,
    coAssignee: undefined,
    participant: undefined,
    daysFromCreationDueDate: template.daysFromCreationDueDate
      ? template.daysFromCreationDueDate.toString()
      : "0",
    taskOrganization: "1",
    taskType: "1",
    taskSubType: "4",
    dueDate: "",
    workflowGroup: "Standard",

    // We can pre-populate existing tags from 'template' if it has them
    tags: template.tags?.map(obj => obj.id) // Or from template if you have that data
  });

  // 1) Fetch the template files
  useEffect(() => {
    if (currentUser) {
      const fetchTemplateFiles = async () => {
        try {
          const authToken: string = await currentUser.getIdToken();
          const fetchedTemplateFiles = await getTemplateFiles(authToken);
          setFileTemplates(fetchedTemplateFiles);
        } catch (err) {
          console.error("Error fetching template files:", err);
        }
      };
      fetchTemplateFiles();
    }
  }, [currentUser]);

  // 2) Format the template files for react-select
  useEffect(() => {
    if (fileTemplates) {
      const newOptions = fileTemplates.map((file) => ({
        value: file.id.toString(),
        label: file.fileName,
      }));
      setFormattedFileTemplates(newOptions);
    }
  }, [fileTemplates]);

  // 3) Fetch the tags
  useEffect(() => {
    if (currentUser) {
      const fetchAllTags = async () => {
        try {
          const authToken = await currentUser.getIdToken();
          const tags = await getAllTags(authToken);
          if (tags) {
            setAllTags(tags);
          }
        } catch (error) {
          console.error("Error fetching tags:", error);
        }
      };
      fetchAllTags();
    }
  }, [currentUser]);

  // 4) Format the tags for react-select
  useEffect(() => {
    if (allTags.length > 0) {
      const newTagOptions = allTags.map((tag) => ({
        value: tag.id,
        label: tag.name,
      }));
      setFormattedTags(newTagOptions);
    }
  }, [allTags]);

  // 5) (Optional) If your template already has tags, you can pre-load them:
  //    e.g. if template.tags is an array of Tag objects or just IDs
  //    For now, you can skip or do:
  // useEffect(() => {
  //   if(template.tags) {
  //     const existingTagIds = template.tags.map(t => t.id);
  //     setNewTemplate({...newTemplate, tags: existingTagIds});
  //   }
  // }, [template, newTemplate]);

  const handleDayInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewTemplate({
      ...newTemplate,
      daysFromCreationDueDate: event.target.value,
    });
  };

  // Handle changes for the text fields
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setNewTemplate({ ...newTemplate, [name]: value });
  };

  // Handle requiredFile changes from react-select
  const handleRequiredFileChanges = (
    selectedOptions: MultiValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    const requiredFiles = selectedOptions
      ? selectedOptions.map((option) => option.value.toString())
      : [];
    setNewTemplate({ ...newTemplate, requiredFiles });
  };

  // Handle WYSIWYG info changes
  const handleInfoChange = (infoText: string) => {
    setNewTemplate({ ...newTemplate, information: infoText });
  };

  // ===== NEW: handle Tag changes =====
  const handleTagChanges = (
    selectedOptions: MultiValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    // We store the array of numeric IDs (or strings if you prefer).
    const selectedTagIds = selectedOptions.map((opt) => Number(opt.value));
    setNewTemplate({ ...newTemplate, tags: selectedTagIds });
  };

  // Submit
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const createTask = async () => {
      if (currentUser) {
        try {
          const authToken: string = await currentUser.getIdToken();
          const newTaskId: number | null = await updateInterimTemplate(authToken, newTemplate);
          return newTaskId;
        } catch (error) {
          console.error("Failed to update interim template:", error);
          return null;
        }
      }
      return null;
    };

    createTask().then((id) => {
      if (id !== null) {
        setSelectedTemplate(undefined);
      } else {
        console.error("Failed to create/update task");
      }
    });
  };

  return (
    <div className="mt-5 remove-input-txt-border">
      <h1 className="heading-2 text-center">Edit Interim Template</h1>
      <form
        onSubmit={handleSubmit}
        className="max-w-xl mx-auto mt-8 bg-card dark:text-text dark:bg-card-dark shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        {/* Title */}
        <div className="mb-4">
          <label
            htmlFor="title"
            className="block text-text dark:text-text-dark text-sm font-bold mb-2"
          >
            Title:
          </label>
          <input
            type="text"
            id="title"
            name="title"
            placeholder="Give your task a title..."
            value={newTemplate.title}
            onChange={handleChange}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-text leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        {/* Description */}
        <div className="mb-4">
          <label
            htmlFor="description"
            className="block text-text dark:text-text text-sm font-bold mb-2"
          >
            Description:
          </label>
          <textarea
            id="description"
            name="description"
            placeholder="A brief description of what the task entails..."
            value={newTemplate.description}
            onChange={handleChange}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-text dark:text-text leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        {/* Information (WYSIWYG) */}
        <div className="mb-4">
          <label
            htmlFor="info"
            className="block text-text dark:text-text text-sm font-bold mb-2"
          >
            Information:
          </label>
          <TextEditor text={newTemplate.information} setText={handleInfoChange} />
        </div>

        {/* Required Files */}
        <div className="mb-4">
          <label
            htmlFor="requiredFiles"
            className="block text-text dark:text-text-dark text-sm font-bold mb-2"
          >
            Required Files <span className="text-muted font-normal">(Optional)</span>:
          </label>
          <Select
            options={formattedFileTemplates}
            isMulti
            onChange={handleRequiredFileChanges}
            value={formattedFileTemplates.filter((option) =>
              newTemplate.requiredFiles.includes(option.value.toString())
            )}
          />
        </div>

        {/* Days from creation (due date) */}
        <div className="my-5 flex-col">
          <div className="text-sm mb-1">Due date from creation</div>
          <input
            className="w-full border p-2 rounded-md"
            type="number"
            min={"1"}
            max={"365"}
            value={newTemplate.daysFromCreationDueDate}
            onChange={handleDayInputChange}
            placeholder={"Days until completion..."}
          />
        </div>

        {/* ===== NEW: Tags ===== */}
        <div className="mb-4">
          <label
            htmlFor="tags"
            className="block text-text dark:text-text-dark text-sm font-bold mb-2"
          >
            Tags <span className="text-muted font-normal">(Optional)</span>:
          </label>
          <Select
            options={formattedTags}
            isMulti
            onChange={handleTagChanges}
            // Pre-select tags if newTemplate.tags is not empty
            value={formattedTags.filter((tagOption) =>
              newTemplate.tags?.includes(Number(tagOption.value))
            )}
          />
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Save Template
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditTemplateInterimTask;
