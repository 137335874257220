// FileUpload.tsx

import React, { useState } from "react";
import { useAuth } from "../../../firebase/AuthProvider";
import { uploadFile } from "../../../services/fileServices/FileService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightLong,
  faArrowUpFromBracket,
  faFile,
} from "@fortawesome/free-solid-svg-icons";

interface FileUploadFromTemplateProps {
  toggleFileUpload: (message: string) => void;
  taskId: string;
  intendedFilename: string;
}

const FileUploadFromTemplate: React.FC<FileUploadFromTemplateProps> = ({
  toggleFileUpload,
  taskId,
  intendedFilename,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleUpload = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setIsLoading(true);
    // Handle upload logic here
    if (selectedFile) {
      const modifiedFileName = `${intendedFilename}`;
      const modifiedFile = new File([selectedFile], modifiedFileName, {
        type: selectedFile.type,
      });
      if (currentUser !== null) {
        const authToken: string = await currentUser.getIdToken();

        // Extract file metadata
        const fileSize = selectedFile.size;
        const mimeType = selectedFile.type || "application/octet-stream"; // Fallback for unknown MIME types

        await uploadFile(
          modifiedFile,
          taskId,
          mimeType,
          fileSize,
          authToken
        ).then((success) => {
          if (success) {
            toggleFileUpload("Success");
            setIsLoading(false);
          } else {
            console.error("Error uploading file.");
            setIsLoading(false);
          }
        });
      }
    } else {
      console.error("No file selected");
      setIsLoading(false);
    }
  };

  // document.addEventListener("DOMContentLoaded", () => {
  //   const dropzone = document.getElementById("dropzone");

  //   dropzone?.addEventListener("dragover", (e) => {
  //     e.preventDefault();
  //     dropzone.classList.add("border-blue-500"); // Add some visual indication that the dropzone is active
  //   });

  //   dropzone?.addEventListener("dragleave", () => {
  //     dropzone.classList.remove("border-blue-500"); // Remove the visual indication when dragging leaves the dropzone
  //   });
  // });

  return (
    <div
      id="dropzone"
      className={`items-center -top- justify-center card rounded-lg shadow-lg p-1 z-50`}
    >
      <div className="bg-card dark:bg-card-dark shadow-md rounded w-full">
        <div className="rounded-md mb-4 border-2 border-primary-dark border-dashed hover:bg-text-dark">
          <label
            className="block text-text dark:text-text-dark text-sm mb-2"
            htmlFor="fileUpload"
          >
            <div className="py-4 flex-col justify-center align-middle text-center text-muted">
              <div className="text-2xl">
                <FontAwesomeIcon icon={faArrowUpFromBracket} />
              </div>
              <div>
                <span className="font-bold">Click to upload</span>
              </div>
              <div className="text-xs">
                SVG, PNG, JPG or GIF (MAX. 800x400px)
              </div>
            </div>
          </label>
          <input
            id="fileUpload"
            type="file"
            accept=".jpg,.jpeg,.png,.gif,.pdf"
            onChange={handleFileChange}
            className="hidden"
          />
        </div>
        <div className="flex-col">
          {selectedFile && (
            <div className="text-sm flex py-2 text-start text-muted">
              <div className="flex text-lg align-middle ms-4 me-2">
                <FontAwesomeIcon icon={faArrowRightLong} />
              </div>
              <div className="flex text-lg align-middle me-2">
                <FontAwesomeIcon icon={faFile} />
              </div>
              <div className="flex align-middle truncate max-w-44">
                {selectedFile.name}
              </div>
            </div>
          )}
          {isLoading ? (
            <button
              disabled={true}
              className={`bg-primary hover:bg-primary-dark text-text-dark font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full primary-button-disabled`}
            >
              Upload
            </button>
          ) : (
            <button
              disabled={!selectedFile}
              onClick={handleUpload}
              className={`bg-primary hover:bg-primary-dark text-text-dark font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full ${
                !selectedFile ? "primary-button-disabled" : ""
              }`}
            >
              Upload
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileUploadFromTemplate;
