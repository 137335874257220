// TemplateTaskService.ts

import TemplateFile from "../../interfaces/task/TaskFiles/TemplateFile";
import TaskGroup from "../../interfaces/task/workflow/TaskGroup";
import NewTemplateTask from "../../interfaces/templateTask/NewTemplateTask";
import NewTemplateTaskRequest from "../../interfaces/templateTask/NewTemplateTaskRequest";
import TemplateFileRequest from "../../interfaces/templateTask/TemplateFileRequest";
import TemplateRequiresFile from "../../interfaces/templateTask/TemplateRequiresFile";
import TemplateTask from "../../interfaces/templateTask/TemplateTask";
import TaskGroupOption from "../../interfaces/templateTask/TaskGroupOption";
import TemplateTaskShortResponse from "../../interfaces/templateTask/TemplateTaskShortResponse";
import CustomBactchRunTemplate from "../../interfaces/templateTask/CustomBatchRunTemplate";

export async function publishTemplateTask(
  taskRequest: NewTemplateTask,
  authToken: string
): Promise<number | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/template/task`;

  // Converet NewTask type to TaskRequest type
  const requestBody: NewTemplateTaskRequest = {
    title: taskRequest.title,
    description: taskRequest.description,
    organizationId: 1,
    taskOrganizationId: Number(taskRequest.taskOrganization),
    taskTypeId: Number(taskRequest.taskType),
    taskSubTypeId: Number(taskRequest.taskSubType),
    dueDateCalculationType: taskRequest.dueDateCalculationType,
    dueDateOffset: Number(taskRequest.dueDateOffset),
    creationDateCalculationType: taskRequest.creationDateCalculationType,
    creationDateOffset: Number(taskRequest.creationDateOffset),
    creationDateCron: taskRequest.creationDateCron,
    dueDateCron: taskRequest.dueDateCron,
    distributionPattern: taskRequest.assignmentType,
    taskGroupId: Number(taskRequest.taskGroup), 
    waiverId: Number(taskRequest.waiverId) === 0 ? null : Number(taskRequest.waiverId),
    role: taskRequest.role
  };

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      console.log(response);
      return null;
    }

    const taskId = await response.text();

    return parseInt(taskId, 10);
  } catch (error) {
    console.log("Error posting comment", error);
    return null;
  }
}

export async function getTemplateTasks(
  authToken: string
): Promise<TaskGroup | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/template/tasks`;

  try {
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.log(response);
    }

    const template = await response.json();

    return template;
  } catch (error) {
    console.error("Error posting comment", error);
    return null;
  }
}

export async function getTemplateTask(
  templateTaskId: number,
  authToken: string
): Promise<TemplateTask | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/template/task/${templateTaskId}`;

  try {
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.log(response);
    }

    const responseBody = await response.json();

    // Converet NewTask type to TaskRequest type
    const template: TemplateTask = {
      id: responseBody.id,
      title: responseBody.title,
      description: responseBody.description,
      groupId: null,
      waiverName: responseBody.waiverName,
      taskOrganization: {
        id: responseBody.taskOrganization.id,
        type: responseBody.taskOrganization.name,
      },
      taskType: {
        id: responseBody.taskType.id,
        type: responseBody.taskType.name,
      },
      taskSubType: {
        id: responseBody.taskSubType.id,
        type: responseBody.taskSubType.name,
      },
      distributionPattern: responseBody.distributionPattern,
      schedulePattern: responseBody.schedulePattern,
      dueDateCalculationType: responseBody.dueDateCalculationType,
      dueDateOffset: responseBody.dueDateOffset,
      creationDateCalculationType: responseBody.creationDateCalculationType,
      creationDateOffset: responseBody.creationDateOffset,
      creationCron: responseBody.creationCron,
      dueDateCron: responseBody.dueDateCron, 
      tags: responseBody.tags
    };

    return template;
  } catch (error) {
    console.error("Error posting comment", error);
    return null;
  }
}

export async function getAllTemplateTasks(
  authToken: string
): Promise<TemplateTaskShortResponse[] | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/template/task/all`;

  try {
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.log(response);
    }

    const responseBody = await response.json();

    // Converet NewTask type to TaskRequest type
    const template: TemplateTaskShortResponse[] = responseBody.map((tt:any) => ({
      id: tt.id,
      title: tt.title,
      description: tt.description,
    }));

    return template;
  } catch (error) {
    console.error("Error posting comment", error);
    return null;
  }
}

export async function submitTaskToAddToGroup(
  authToken: string,
  templateTaskId: string
): Promise<string | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/template/task/group/${templateTaskId}`;

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.log(response);
      return null;
    }

    return response.text();
  } catch (error) {
    console.log("Error posting comment", error);
    return null;
  }
}

export async function getTemplateRequiredFiles(
  templateTaskId: number,
  authToken: string
): Promise<TemplateFile[] | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/template/task/${templateTaskId}/requiredFiles`;

  try {
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const templateFiles = await response.json();

    const templateRequiredFiles: TemplateFile[] = templateFiles.map(
      (action: any) => ({
        id: action.id,
        templateTaskId: action.templateTaskId,
        description: action.description,
        actionType: {
          id: action.actionType.id,
          type: action.actionType.name,
        },
        actionSubType: {
          id: action.actionSubType.id,
          type: action.actionSubType.name,
        },
        estimated_ttc: action.estimatedTtc,
      })
    );

    return templateRequiredFiles;
  } catch (error) {
    console.log("Error posting comment", error);
    return null;
  }
}

export async function publishTemplateRequiredFile(
  templateRequiredFile: TemplateFile,
  taskId: number,
  authToken: string
): Promise<string | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/template/task/requiredFile`;

  // Converet NewTask type to TaskRequest type
  const requestBody: TemplateFileRequest = {
    TemplateFileId: templateRequiredFile.id,
    TemplateTaskId: taskId,
  };

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      console.log(response);
      return null;
    }

    return response.text();
  } catch (error) {
    console.log("Error posting comment", error);
    return null;
  }
}

export async function customBatchRun(
  startDate: string,
  endDate: string,
  id: string,
  authToken: string
): Promise<string | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/template/task/batch/custom`;

  // Converet NewTask type to TaskRequest type
  const requestBody: CustomBactchRunTemplate = {
    TemplateId: id,
    StartDate: startDate,
    EndDate: endDate
  };

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      console.log(response);
      return null;
    }

    return response.text();
  } catch (error) {
    console.log("Error posting comment", error);
    return null;
  }
}

export async function getTemplateTaskRequiredFiles(
  templateTaskId: number,
  authToken: string
): Promise<TemplateRequiresFile[] | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/template/task/${templateTaskId}/requiredFiles`;

  try {
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.error(`HTTP error! status: ${response.status}`);
    }

    const templateFiles = await response.json();

    const templateTaskFiles: TemplateRequiresFile[] = templateFiles.map((file: any) => ({
        id: file.id,
        templateTaskId: file.templateTaskId, 
        templateFile: file.templateFile
    }));

    return templateTaskFiles;
  } catch (error) {
    console.log("Error posting comment", error);
    return null;
  }
}

export async function deleteTemplateTaskRequiredFile(
  templateRequiredFileId: number, 
  authToken: string
): Promise<boolean> { 
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/template/task/requiredFile/${templateRequiredFileId}`;

  try {
    const response = await fetch(apiUrl, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.log(response);
      return false;
    }

    return response.ok
  } catch (error) {
    console.log("Error posting comment", error);
    return false;
  }
}

export async function removeUserFromTaskGroup(
  userId: string, 
  groupId: number, 
  authToken: string
): Promise<boolean> { 
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/template/task/taskGroup/employee`;

  const requestBody = { 
    userId: userId, 
    groupId: groupId
  };

  try {
    const response = await fetch(apiUrl, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      console.error(response);
      return false;
    }

    return response.ok
  } catch (error) {
    console.error("Error removing user from group", error);
    return false;
  }
}

export async function addUserToTaskGroup(
  userId: string, 
  groupId: number, 
  authToken: string
): Promise<boolean> { 
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/template/task/taskGroup/employee`;

  const requestBody = { 
    userId: userId, 
    groupId: groupId
  };

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      console.error(response);
      return false;
    }

    return response.ok
  } catch (error) {
    console.error("Error removing user from group", error);
    return false;
  }
}

export async function getTaskGroupOptions(
  authToken: string
): Promise<TaskGroupOption[] | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/template/task/options`;

  try {
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const templateFiles = await response.json();

    const templateRequiredFiles: TaskGroupOption[] = templateFiles;

    return templateRequiredFiles;
  } catch (error) {
    console.log("Error posting comment", error);
    return null;
  }
}
